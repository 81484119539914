import React, { useState } from "react";
import { Box, SimpleGrid, Flex, Button, useColorModeValue, Radio, Stack, Image } from "@chakra-ui/react";
import Svg_tableIcon from 'assets/img/icons/table.svg';
import Svg_calendarIcon from 'assets/img/icons/calendar.svg';
import Svg_table_active from 'assets/img/icons/table_active.svg';
import Svg_calendar_active from 'assets/img/icons/calendar_active.svg';
import { Fonts } from "theme/theme";

export default function SwitchTab({ onChange, mode, style }) {
  const tabBgColor = useColorModeValue('gray.400', 'whiteAlpha.100');
  const tabTxtColor = useColorModeValue('blue.2', 'whiteAlpha.100');
  const inactivetabTxtColor = useColorModeValue('textColor.2', 'whiteAlpha.100');

  return (
    <Flex direction='row' style={style}>
      <Button
        leftIcon={
          <Image src={mode == 'table' ? Svg_table_active : Svg_tableIcon} />
        }
        backgroundColor={mode == 'table' ? tabBgColor : 'transparent'}
        _hover={{
          backgroundColor: (mode == 'table' ? tabBgColor : 'transparent')
        }}
        borderRadius='8px'
        color={mode == 'table' ? tabTxtColor : inactivetabTxtColor}
        fontSize='14px'
        fontFamily={mode == 'table' ? Fonts.bold : Fonts.medium}
        fontWeight={'normal'}
        px={'12px'}
        py={'9px'}
        onClick={() => onChange('table')}
      >
        Table
      </Button>
      <Button
        leftIcon={
          <Image src={mode != 'table' ? Svg_calendar_active : Svg_calendarIcon} />
        }
        backgroundColor={mode != 'table' ? tabBgColor : 'transparent'}
        _hover={{
          backgroundColor: (mode != 'table' ? tabBgColor : 'transparent')
        }}
        borderRadius='8px'
        color={mode != 'table' ? tabTxtColor : inactivetabTxtColor}
        fontSize='14px'
        fontFamily={mode != 'table' ? Fonts.bold : Fonts.medium}
        fontWeight={'normal'}
        px={'12px'}
        py={'9px'}
        onClick={() => onChange('calendar')}
      >
        Calendar
      </Button>
    </Flex>
  );
}
