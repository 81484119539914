import { Avatar, Box, Button, Flex, Text, Image, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { getImageFullURL } from 'utils/common';
import Svg_dots from 'assets/img/icons/dots-vertical.svg'
import { Fonts } from 'theme/theme';
import { isEmpty } from 'utils/common';

export default function SuperFanItem({ data }) {
  const bgColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const nameColor = useColorModeValue('textColor.0', 'white');
  const unameColor = useColorModeValue('textColor.1', 'white');
  return (
    <Box backgroundColor={bgColor} borderRadius={'12px'} width={'100%'} padding={'24px'}>
      <Flex width={'100%'} justifyContent={'space-between'}>
        <Flex justifyContent={'center'} alignItems={'center'}>
          <Avatar
            src={getImageFullURL(data?.photo)}
            width={'48px'} height={'48px'}
          />
          <Flex direction={'column'} ms={'16px'} justifyContent='flex-start' alignItems={'flex-start'}>
            <Text fontSize={'14px'} fontFamily={Fonts.semibold} color={nameColor}>{data?.full_name}</Text>
            <Text fontSize={'12px'} fontFamily={Fonts.medium} color={isEmpty(data?.username) ? '#f55a00' : unameColor}>@{isEmpty(data?.username) ? ' - no username -' : data?.username}</Text>
          </Flex>
        </Flex>
        <Image src={Svg_dots} width={'24px'} height={'24px'} />
      </Flex>
      <Button
        width={'100%'}
        height={'48px'}
        borderRadius={'12px'}
        backgroundColor={'white'}
        fontSize={'14px'}
        fontFamily={Fonts.bold}
        color={'textColor.2'}
        mt={'32px'}
      >
        Message
      </Button>
    </Box>
  )
}
