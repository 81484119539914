import { ApiFactory } from '../index';

const PREFIX = '/superadmin/interests';

const API_LIST = {
  FETCH_ALL: `${PREFIX}`,
};

export const InterestsService = {
  getAllInterests(searQuery = '') {
    return ApiFactory.get(`${API_LIST.FETCH_ALL}${searQuery}`);
  },
  createInterest(data) {
    return ApiFactory.post(API_LIST.FETCH_ALL, data);
  },
  deleteInterest(data) {
    return ApiFactory.post(`${API_LIST.FETCH_ALL}/delete`, data);
  },
};
