export const columnsDataQuiz = [
  {
    Header: 'No',
    accessor: 'id',
  },
  {
    Header: 'QUIZ TITLE',
    accessor: 'title',
  },
  {
    Header: 'NR OF COMPETITIONS',
    accessor: 'nr_competitions',
  },
  {
    Header: 'USER CREATED',
    accessor: 'registered_users',
  },

  // {
  //   Header: 'Action',
  //   accessor: 'action',
  // },
];
