import React, { useEffect, useState } from 'react';
import Card from 'components/card/Card.js';
import { Flex, Input, SimpleGrid, Box, Button, useToast, Grid, GridItem, useColorModeValue, Badge, Alert } from "@chakra-ui/react";
import { useQuery } from 'react-query';
import { VendorApplicationService } from 'services/vendor_applications';
import { useHistory, useParams } from 'react-router-dom';
import { Fonts } from 'theme/theme';
import { AlertIcon } from '@chakra-ui/react';
import { AiOutlineArrowLeft, AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

const industryList = [
    { value: "", title: "Choose industry" },
    { value: "Food", title: "Food" },
    { value: "Sport & Entertainment", title: "Sport & Entertainment" },
    { value: "Accommodation", title: "Accommodation" },
    { value: "Retail", title: "Retail" },
    { value: "Healthcare", title: "Healthcare" },
];

const valueToTitleMapForIndustry = industryList.reduce((acc, { value, title }) => {
    acc[value] = title;
    return acc;
}, {});

const categoryList = [
    { value: "", title: "Choose category" },
    { value: "restaurant", title: "Restaurant" },
    { value: "bistro", title: "Bistro" },
    { value: "bar", title: "Bar" },
    { value: "cafe", title: "Cafe" },
    { value: "pub_club", title: "Pub & Club" },
    { value: "food_truck", title: "Food Truck" },
    { value: "golf_venue", title: "Golf Venue" },
    { value: "bowling", title: "Bowling" },
    { value: "gym", title: "Gym" },
    { value: "hotel", title: "Hotel" },
    { value: "cozy_retreat", title: "Cozy Retreat" },
    { value: "hostel", title: "Hostel" },
    { value: "hotel_chain", title: "Hotel Chain" },
    { value: "vacation_rental", title: "Vacation Rental" },
    { value: "sport_essentials", title: "Sport Essentials" },
    { value: "home_decor", title: "Home Decor" },
    { value: "fashion_threads", title: "Fashion & Threads" },
    { value: "hospital", title: "Hospital" },
    { value: "dental_clinic", title: "Dental Clinic" },
];

const categoryValueToTitleMap = {};
categoryList.forEach((category) => {
    categoryValueToTitleMap[category.value] = category.title;
});

export default function VendorContactDetails(props) {
    const toast = useToast();
    const { id: contact_id } = useParams();
    const history = useHistory();
    const [data, setData] = useState({});
    const [isRejecting, setRejecting] = useState(false);
    const [isApproving, setApproving] = useState(false);

    useEffect(() => {
        loadData(contact_id);
    }, [contact_id])

    const loadData = (id) => {
        VendorApplicationService.get(`/${id}`)
            .then(({ data }) => {
                setData(data?.contact_sale);
            })
            .catch((err) => {
            })
    }

    const onUpdateStatus = (new_status) => {
        if (new_status == 'approved') {
            setApproving(true);
        }
        else {
            setRejecting(true);
        }
        VendorApplicationService.update(contact_id, { status: new_status })
            .then(({ data }) => {
                if (new_status == 'approved') {
                    setApproving(false);
                }
                else {
                    setRejecting(false);
                }
                onGoBack();
                toast({
                    title: `Venue Application status changed successfully! The venue has been notified`,
                    status: 'success',
                    position: 'top-right',
                    isClosable: true,
                })
            })
            .catch((err) => {
                if (new_status == 'approved') {
                    setApproving(false);
                }
                else {
                    setRejecting(false);
                }
                toast({
                    title: `There was a problem changing the status. Please try again`,
                    status: 'error',
                    position: 'top-right',
                    isClosable: true,
                })
            })
    }

    const onGoBack = () => {
        history.goBack();
    }

    return (
        <SimpleGrid
            mb='20px'
            columns={{ sm: 1, md: 1 }}
            spacing={{ base: "20px", xl: "20px" }}
            className={'promo-config custom-form'}
        >
            <Card
                direction="column"
                w="100%"
                px="20px"
                overflowX={{ sm: 'scroll', lg: 'hidden' }}
            >
                <Flex justify={'flex-start'} align={'center'}>
                    <h4 style={{ fontSize: 20, marginRight: 10, fontWeight: 'bold' }}>Venue Application</h4>
                    <Badge colorScheme={data?.status == 'pending' ? null : data?.status == 'approved' ? 'green' : 'red'}>{data?.status}</Badge>
                </Flex>

                <Box w='100%' mt={4} p={4} borderRadius={'12px'} border={'1px'} borderColor={'gray.350'} borderStyle={'solid'} style={{ position: 'relative' }}>
                    <Grid
                        mb='20px'
                        templateColumns={{
                            base: "1fr",
                            md: "repeat(2, 1fr)"
                        }}
                        gap={{ base: "20px", xl: "20px" }}>
                        <GridItem >
                            <div className='label'>First Name</div>
                            <Input
                                value={data?.first_name}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Last Name</div>
                            <Input
                                value={data?.last_name}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Email</div>
                            <Input
                                value={data?.email}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Mobile</div>
                            <Input
                                value={data?.mobile}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Name</div>
                            <Input
                                value={data?.restaurant_name}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>City</div>
                            <Input
                                value={data?.restaurant_city}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Venue State/Province</div>
                            <Input
                                value={data?.restaurant_state}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Venue Zip/Postal Code</div>
                            <Input
                                value={data?.restaurant_zipcode}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Industry</div>
                            <Input
                                value={valueToTitleMapForIndustry[data?.industry]}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Category</div>
                            <Input
                                value={categoryValueToTitleMap[data?.category]}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Venue Country</div>
                            <Input
                                value={data?.restaurant_country}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Contact Reason</div>
                            <Input
                                value={data?.contact_reason}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                        <GridItem >
                            <div className='label'>Demo</div>
                            <Input
                                value={data?.is_demo ? 'Yes' : 'No'}
                                onChange={(e) => {
                                }}
                            />
                        </GridItem>
                    </Grid>
                </Box>

                <Flex mt={4} px="25px" align="center" justify={'flex-end'}>
                    <Button className={'app-main-btn'} w={'200px'} mr={'20px'} leftIcon={<AiOutlineArrowLeft />} onClick={onGoBack}>
                        Back to List
                    </Button>
                    {
                        data?.status == 'pending' &&
                        <>
                            <Button isLoading={isRejecting} loadingText={'Saving...'} className={'app-main-btn cancel'} mr={'20px'} w={'200px'} rightIcon={<AiOutlineClose />} onClick={() => onUpdateStatus('declined')}>
                                Reject
                            </Button>
                            <Button isLoading={isApproving} loadingText={'Saving...'} className={'app-main-btn'} w={'200px'} rightIcon={<AiOutlineCheck />} onClick={() => onUpdateStatus('approved')}>
                                Approve
                            </Button>
                        </>
                    }
                </Flex>
            </Card>
        </SimpleGrid>
    );
}
