import {
  Flex,
  FormLabel,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Text,
  Icon,
  useColorModeValue,
  Image,
  Img,
} from '@chakra-ui/react';
// Custom components
import React from 'react';
import Svg_calendar from 'assets/img/icons/calendar.svg'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './index.css';

export default function CustomDatePicker({ is_schedule, schedule_date, onChange }) {

  return (
    <Flex className='custom-date-picker'>
      <DatePicker
        disabled={is_schedule != true}
        selected={schedule_date}
        onChange={onChange}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeSelect
        className='date-picker'
        minDate={new Date()}
        filterTime={(time) => {
          const currentDate = new Date();
          const selectedDate = new Date(time);
          return currentDate.getTime() < selectedDate.getTime();
        }}
      />
      <Img src={Svg_calendar} w={'20px'} zIndex={1} marginLeft={'-32px'}/>
    </Flex>
  );
}
