/* eslint-disable */
import React, { useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useColorMode, Box, Flex, HStack, Text, useColorModeValue, Accordion, AccordionIcon, AccordionItem, AccordionPanel, AccordionButton, Switch, Img, Avatar } from "@chakra-ui/react";
import './index.css';
import { Fonts } from "theme/theme";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from 'store/actions/auth';

export function SidebarLinks(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("textColor.2", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("textColor.2", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  let catColor = useColorModeValue("textColor.3", "white");
  let activeBgColor = useColorModeValue("gray.200", "whiteAlpha.200");
  let inactiveBgColor = useColorModeValue(
    "transparent",
    "transparent"
  );

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = useCallback((routeName) => {
    return location.pathname.includes(routeName);
  }, [location]);
  const activeCategory = useCallback((items) => {
    if (items == null) return false;
    return items.findIndex(item => location.pathname.includes(item.path.toLowerCase())) != -1;
  }, [location]);

  const handleLogout = async () => {
    await dispatch(logout());
    history.push('/auth/sign-in/default');
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.filter(r => r.isSidebar != false).map((route, index) => {
      if (route.category) {
        return (
          <>
            <Text
              fontSize={13}
              color={catColor}
              fontFamily={Fonts.semibold}
              mx='auto'
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              mt={index > 1 ? '18px' : '0px'}
              pt='32px'
              pb='12px'
              key={index}
              borderTop={index > 1 ? '1px solid #F1F5F9' : ''}
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      }
      else if (route.collapse) {
        return (
          <>
            <Accordion className="sidebar-category-menu" defaultIndex={route.items?.findIndex(r => activeRoute(r.path.toLowerCase())) != -1 ? [0] : []} allowMultiple>
              <AccordionItem>
                <AccordionButton
                  backgroundColor={
                    activeCategory(route.items) ? activeBgColor : inactiveBgColor
                  }
                  px='12px'
                  py={'13px'}
                  borderRadius={'12px'}
                  mt={'4px'}
                >
                  <HStack
                    spacing={"26px"}
                    flex={1}
                  >
                    <Flex w='100%' alignItems='center' justifyContent='center'>
                      <Box
                        color={textColor}
                        me='16px'
                      >
                        {
                          route.isImg == true ?
                            route.icon :
                            <route.icon />
                        }
                      </Box>
                      <Text
                        me='auto'
                        color={textColor}
                        fontSize={15}
                      >
                        {route.name}
                      </Text>
                    </Flex>
                  </HStack>
                  <AccordionIcon color={textColor} />
                </AccordionButton>
                <AccordionPanel pb={4} pe={'0px'}>
                  {createLinks(route.items)}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        );
      } else if (
        route.layout === "/admin" ||
        // route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        if (route.darkModeBtn == true) {
          return (
            <Box
              backgroundColor={inactiveBgColor}
              borderRadius={'12px'}
              px={'12px'}
              py={'13px'}
              mt={'4px'}
            >
              <HStack
                spacing={"26px"}
              >
                <Flex w='100%' alignItems='center' justifyContent='center'>
                  <Box
                    color={textColor}
                    me='16px'
                  >
                    {
                      route.isImg == true ?
                        route.icon :
                        <route.icon />
                    }
                  </Box>
                  <Text
                    me='auto'
                    color={textColor}
                    fontSize={15}
                  >
                    {route.name}
                  </Text>
                  <Switch isChecked={colorMode == 'dark'} onChange={toggleColorMode} />
                </Flex>
              </HStack>
            </Box>
          );
        }
        return (
          <NavLink key={index} to={route.path == '/logout' ? '#' : (route.layout + route.path)} className='sidebar-link'
            onClick={() => {
              if (route.path == '/logout') {
                handleLogout()
              }
            }}
          >
            {route.icon ? (
              <Box
                backgroundColor={
                  activeRoute(route.path.toLowerCase()) ? activeBgColor : inactiveBgColor
                }
                borderRadius={'12px'}
                px={'12px'}
                py={'13px'}
                mt={'4px'}
              >
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                >
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      className=""
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='16px'
                    >
                      {
                        route.isImg == true ?
                          route.icon :
                          <route.icon className={(activeRoute(route.path.toLowerCase()) ? 'active' : 'inactive')} />
                      }
                    </Box>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontSize={15}
                      fontFamily={
                        activeRoute(route.path.toLowerCase())
                          ? Fonts.bold
                          : Fonts.regular
                      }>
                      {route.name}
                    </Text>
                  </Flex>
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
