import { ApiFactory } from 'services';

export const API_QUIZ = '/superadmin/quizzes';

export const quizzService = {
  getQuizzes() {
    return ApiFactory.get(API_QUIZ);
  },
  quizConfig(data) {
    return ApiFactory.post(`${API_QUIZ}/store-config`, data);
  },

  updateQuizConfig(data) {
    return ApiFactory.put(`${API_QUIZ}/update-config`, data);
  },
};
