import React from 'react';

import { Box, Icon, Image } from '@chakra-ui/react';
import {
  MdHome,
  MdAutoGraph,
  MdCampaign,
  MdOutlineLanguage,
  MdSettings,
  MdOutlineGamepad,
  MdOutlineAttachMoney,
  MdOutlineFoodBank,
  MdOutlineSupervisedUserCircle,
  MdEmail,
  MdAdjust,
  MdLock,
  MdOutlineCalendarToday,
  MdMessage,
  MdTour,
  MdInsights,
  MdAnalytics,
  MdOutlineCardMembership,
  MdLogout,
} from 'react-icons/md';
import { FcDataConfiguration, FcAdvertising } from 'react-icons/fc';
import { SiSololearn } from 'react-icons/si';

import Dashboard from 'views/admin/dashboard';
import OrderStatistics from 'views/admin/statistics/orders';
import PromoStatistics from 'views/admin/statistics/food';
import UsDashboard from 'views/admin/us_dashboard';
import AlDashboard from 'views/admin/al_dashboard';
import Settings from 'views/admin/settings';
import CustomerInsights from 'views/admin/customer_insights';
import Emails from 'views/admin/emails';
import Banners from 'views/admin/banners';
import Website from 'views/admin/website';
import Reports from 'views/admin/reports';
import Feedbacks from 'views/admin/feedbacks';
import SignInCentered from 'views/auth/signIn';
import Calendar from 'views/admin/calendar';
import Promotions from 'views/admin/promotions';
import Notifications from 'views/admin/notifications';
import PromoConfig from 'views/admin/configurations/promotions';
import BoostRestaurants from 'views/admin/boost/restaurants';
import BoostConfiguration from 'views/admin/boost/configuration';
import BoostConfigFaq from 'views/admin/boost/configuration/faq';
import BoostConfigBlog from 'views/admin/boost/configuration/blog';
import { MoonIcon } from '@chakra-ui/icons';
// import SuperUsers from './views/admin/superusers';
// Svgs
import { ReactComponent as Svg_dashboard } from './assets/img/sidebar/layout-grid.svg';
import { ReactComponent as Svg_calendar } from './assets/img/sidebar/calendar.svg';
import { ReactComponent as Svg_logout } from './assets/img/sidebar/logout.svg';
import { ReactComponent as Svg_moon } from './assets/img/sidebar/moon-stars.svg';
import { ReactComponent as Svg_photo } from './assets/img/sidebar/photo.svg';
import { ReactComponent as Svg_send } from './assets/img/sidebar/send.svg';
import { ReactComponent as Svg_analytics } from './assets/img/sidebar/analytics-line.svg';
import { ReactComponent as Svg_settings } from './assets/img/sidebar/settings.svg';
import { ReactComponent as Svg_trophy } from './assets/img/sidebar/trophy.svg';
import { ReactComponent as Svg_report } from './assets/img/sidebar/report.svg';
import { ReactComponent as Svg_message } from './assets/img/sidebar/message.svg';
import { ReactComponent as Svg_feedback } from './assets/img/sidebar/feedback.svg';
import { ReactComponent as Svg_user } from './assets/img/sidebar/user.svg';
import { ReactComponent as Svg_table } from './assets/img/icons/table.svg';
import { ReactComponent as Svg_wallet } from './assets/img/sidebar/coin.svg';
import Img_insight from './assets/img/sidebar/Img_insight.png';
import Img_earn from './assets/img/sidebar/Img_earn.png';
import Img_membership from './assets/img/sidebar/Img_membership.png';
import Img_logo_circle from './assets/img/logo_circle.png';
import ReferEarn from 'views/admin/refer_earn';
import SnapfoodPlus from 'views/admin/snapfood_plus';
import Superfans from 'views/admin/superfans';
import Referrers from 'views/admin/referrers';
import Interests from 'views/admin/interests';
import Customers from 'views/admin/customers';
import CustomerDetails from 'views/admin/customers/details';
import SystemConfig from 'views/admin/configurations/system';
import WalletStats from 'views/admin/statistics/wallet';
import { Fonts } from 'theme/theme';
import CardPayments from 'views/admin/card_payments';
import VendorApplications from 'views/admin/boost/vendor_applications';
import VendorContactDetails from 'views/admin/boost/vendor_applications/details';
import CuisineTypes from 'views/admin/cuisine_types';
import Amenities from 'views/admin/ameneties';
import Addons from 'views/admin/addons';
import PricePlans from 'views/admin/price_plans';
import Features from 'views/admin/features';
import SubFeatures from 'views/admin/sub_features';
import CreatePricingPlan from 'views/admin/price_plans/details';
import Quiz from 'views/admin/quiz';

const routes = [
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    exact: true,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  // {
  //   name: 'Food',
  //   layout: '/admin',
  //   icon: <Icon as={MdOutlineFoodBank} width="20px" height="20px" color="inherit" />,
  //   path: '/food',
  //   exact: true,
  //   component: Food,
  // },
  // {
  //   name: 'Friends',
  //   layout: '/admin',
  //   icon: <Icon as={MdOutlineSupervisedUserCircle} width="20px" height="20px" color="inherit" />,
  //   path: '/friends',
  //   exact: true,
  //   component: Friends,
  // },

  {
    name: 'Overview',
    exact: true,
    category: true,
    items: [
      // {
      //   name: 'Dashboard',
      //   desc: "Serves as the main hub for monitoring the platform's performance and taking action as needed",
      //   layout: '/admin',
      //   path: '/dashboard',
      //   exact: true,
      //   icon: Svg_dashboard,
      //   component: Dashboard,
      // },
      {
        name: 'Analytics',
        exact: true,
        collapse: true,
        icon: Svg_analytics,
        items: [
          {
            name: 'Revenue Statistics',
            desc: 'Displays detailed metrics and visualizations for revenue statistics that help the superadmin understand and analyze various aspects of Snapfood performance',
            layout: '/admin',
            icon: Svg_analytics,
            path: '/analytics',
            exact: true,
            component: OrderStatistics,
          },
          {
            name: 'Promo Statistics',
            desc: 'Displays detailed metrics and visualizations for promotion statistics that help the superadmin understand and analyze various aspects of Snapfood performance',
            layout: '/admin',
            icon: Svg_analytics,
            path: '/promo-analytics',
            exact: true,
            component: PromoStatistics,
          },
          {
            name: 'Wallet Statistics',
            desc: 'Displays detailed metrics and visualizations for wallet statistics that help the superadmin understand and analyze various aspects of Snapfood performance',
            layout: '/admin',
            icon: Svg_wallet,
            path: '/wallet-analytics',
            exact: true,
            component: WalletStats,
          },
        ],
      },
      {
        name: 'Marketing',
        exact: true,
        collapse: true,
        icon: Svg_trophy,
        items: [
          {
            name: 'Promotions',
            desc: 'Allows the superadmin to create and manage various marketing promotions to promote the Snapfood app',
            layout: '/admin',
            icon: Svg_calendar,
            path: '/promotions',
            exact: true,
            component: Promotions,
          },
          {
            name: 'Banners',
            desc: 'Allows the superadmin to create and manage promotional banners to be displayed on the Snapfood app',
            layout: '/admin',
            icon: Svg_photo,
            path: '/banners',
            exact: true,
            component: Banners,
          },
          {
            name: 'Notifications',
            desc: 'Allows the superadmin to create and manage push notifications to be sent to users of the Snapfood app',
            layout: '/admin',
            icon: Svg_calendar,
            path: '/notifications',
            exact: true,
            component: Notifications,
          },
          {
            name: 'Emails',
            desc: 'Allows the superadmin to create and manage email campaigns to be sent to users of the Snapfood app',
            layout: '/admin',
            icon: Svg_send,
            path: '/emails',
            exact: true,
            component: Emails,
          },
          {
            name: 'Quiz',
            desc: 'Allows the superadmin to create and manage quiz to be sent to users of the Snapfood app',
            layout: '/admin',
            icon: Svg_send,
            path: '/quiz',
            exact: true,
            component: Quiz,
          },
        ],
      },
      {
        name: 'Finance',
        exact: true,
        collapse: true,
        icon: Svg_trophy,
        items: [
          {
            name: 'Success Card Payments',
            desc: 'Allows the superadmin to view all succcess card payments to promote the Snapfood app',
            layout: '/admin',
            icon: Svg_calendar,
            path: '/finance/card-payments',
            exact: true,
            component: CardPayments,
          },
        ],
      },
      {
        name: 'Customers',
        desc: 'Serves as the main hub for management of customers',
        layout: '/admin',
        path: '/customers',
        exact: true,
        icon: Svg_user,
        component: Customers,
      },
      {
        name: 'Customers Details',
        desc: 'Serves as the main hub for management of customers',
        layout: '/admin',
        path: '/customers/:id',
        exact: true,
        icon: Svg_user,
        isSidebar: false,
        component: CustomerDetails,
      },
      {
        name: 'Customer Care',
        exact: true,
        collapse: true,
        icon: Svg_message,
        items: [
          {
            name: 'Reports',
            desc: 'Allows the superadmin to manage and respond various reports for Snapfood app',
            layout: '/admin',
            icon: Svg_report,
            path: '/reports',
            exact: true,
            component: Reports,
          },
          {
            name: 'Feedbacks',
            desc: 'Allows the superadmin to manage and respond various feedbacks for Snapfood app',
            layout: '/admin',
            icon: Svg_feedback,
            path: '/feedbacks',
            exact: true,
            component: Feedbacks,
          },
        ],
      },
      {
        name: 'Configurations',
        exact: true,
        collapse: true,
        icon: Svg_settings,
        items: [
          {
            name: 'Promotions',
            desc: 'Allows the superadmin to send personalized reminders to customers who have a promotional offer (discount, coupon) which is close to expiring',
            layout: '/admin',
            icon: Svg_calendar,
            path: '/config-promotions',
            exact: true,
            component: PromoConfig,
          },
          {
            name: 'Preferences',
            desc: 'Allows the superadmin to configure interests',
            layout: '/admin',
            icon: Svg_calendar,
            path: '/config-interest',
            exact: true,
            component: Interests,
          },
          {
            name: 'System',
            desc: 'Allows the superadmin to mange system configuration',
            layout: '/admin',
            icon: Svg_calendar,
            path: '/config-system',
            exact: true,
            component: SystemConfig,
          },
        ],
      },
      // {
      //   name: 'Boost',
      //   exact: true,
      //   collapse: true,
      //   icon: <Image src={Img_logo_circle} width={'24px'} height={'24px'} />,
      //   isImg: true,
      //   items: [
      //     {
      //       name: "Venue Applications",
      //       desc: 'This is venue application list in boost',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/boost/vendor-applications",
      //       exact: true,
      //       component: VendorApplications,
      //     },
      //     {
      //       name: 'Venue Application Details',
      //       desc: 'This is a venue application details.',
      //       layout: '/admin',
      //       path: '/boost/vendor-applications/:id',
      //       exact: true,
      //       icon: Svg_user,
      //       isSidebar: false,
      //       component: VendorContactDetails,
      //     },
      //     {
      //       name: "Restaurants",
      //       desc: 'This is boost restaurant',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/boost/restaurants",
      //       exact: true,
      //       component: BoostRestaurants,
      //     },
      //     {
      //       name: "Configuration",
      //       desc: 'This is boost configuration',
      //       layout: "/admin",
      //       icon: Svg_settings,
      //       path: "/boost/configuration",
      //       exact: true,
      //       component: BoostConfiguration,
      //     },
      //     {
      //       name: 'Faq configuration',
      //       desc: '',
      //       layout: '/admin',
      //       path: '/boost/configuration/faq',
      //       exact: true,
      //       icon: Svg_user,
      //       isSidebar: false,
      //       component: BoostConfigFaq,
      //     },
      //     {
      //       name: 'Blog configuration',
      //       desc: '',
      //       layout: '/admin',
      //       path: '/boost/configuration/blog',
      //       exact: true,
      //       icon: Svg_user,
      //       isSidebar: false,
      //       component: BoostConfigBlog,
      //     },
      //     {
      //       name: "Cuisine Types",
      //       desc: 'Allows the superadmin to configure cuisine types',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/config-cuisine-types",
      //       exact: true,
      //       component: CuisineTypes,
      //     },
      //     {
      //       name: "Amenities",
      //       desc: 'Allows the superadmin to configure amenities',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/config-amenities",
      //       exact: true,
      //       component: Amenities,
      //     },
      //     {
      //       name: "Addons",
      //       desc: 'Allows the superadmin to configure addons',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/config-addons",
      //       exact: true,
      //       component: Addons,
      //     },
      //     {
      //       name: "Pricing Plans",
      //       desc: 'Allows the superadmin to configure pricing plans',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/pricing-plans",
      //       exact: true,
      //       component: PricePlans,
      //     },
      //     {
      //       name: "New Pricing Plans",
      //       desc: 'Allows the superadmin to create pricing plans',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/pricing-plans/new",
      //       exact: true,
      //       isSidebar: false,
      //       component: CreatePricingPlan,
      //     },
      //     {
      //       name: "Edit Pricing Plans",
      //       desc: 'Allows the superadmin to edit pricing plans',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/pricing-plans/edit/:id",
      //       exact: true,
      //       isSidebar: false,
      //       component: CreatePricingPlan,
      //     },
      //     {
      //       name: "Features",
      //       desc: 'Allows the superadmin to configure features of plans',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/config-plan-features",
      //       exact: true,
      //       component: Features,
      //     },
      //     {
      //       name: "Sub Features",
      //       desc: 'Allows the superadmin to configure features of plans',
      //       layout: "/admin",
      //       icon: Svg_calendar,
      //       path: "/config-plan-features/:id",
      //       exact: true,
      //       isSidebar: false,
      //       component: SubFeatures,
      //     },
      //   ],
      // },
    ],
  },
  {
    name: 'United States of America',
    exact: true,
    category: true,
    items: [
      {
        name: 'Dashboard',
        desc: "Serves as the main hub for monitoring the platform's performance in USA and taking action as needed",
        layout: '/admin',
        path: '/overview-us',
        exact: true,
        icon: Svg_dashboard,
        component: UsDashboard,
      },
    ],
  },
  {
    name: 'Albania',
    exact: true,
    category: true,
    items: [
      {
        name: 'Dashboard',
        desc: "Serves as the main hub for monitoring the platform's performance in Albania and taking action as needed",
        layout: '/admin',
        path: '/overview-al',
        exact: true,
        icon: Svg_dashboard,
        component: AlDashboard,
      },
    ],
  },
  {
    name: 'Shortcuts',
    exact: true,
    category: true,
    items: [
      {
        name: 'Customer Insights',
        desc: 'Provides detailed analytics and visualizations on customer behavior and preferences for the Snapfood app',
        layout: '/admin',
        path: '/customer-insights',
        exact: true,
        icon: <Image src={Img_insight} width={'40px'} height={'40px'} />,
        isImg: true,
        component: CustomerInsights,
      },
      {
        name: 'Refer & Earn',
        collapse: true,
        icon: <Image src={Img_earn} width={'40px'} height={'40px'} />,
        isImg: true,
        items: [
          {
            name: 'Referrals',
            desc: 'Allows the superadmin to manage users invitations for referrals and registrations',
            layout: '/admin',
            icon: Svg_table,
            path: '/referrers',
            exact: true,
            component: Referrers,
          },
        ],
      },
      {
        name: 'Snapfood+',
        desc: 'Allows the superadmin to manage Snapfood+ module configurations the Snapfood app',
        layout: '/admin',
        path: '/snapfood-plus',
        exact: true,
        icon: <Image src={Img_membership} width={'40px'} height={'40px'} />,
        isImg: true,
        component: SnapfoodPlus,
      },
      {
        name: 'Superfans',
        desc: 'Allows the superadmin to manage Snapfood+ module configurations the Snapfood app',
        layout: '/admin',
        path: '/superfans',
        icon: (
          <Box
            className="align-col-middle"
            borderRadius={'10px'}
            fontFamily={Fonts.semibold}
            color={'#fff'}
            letterSpacing={'1px'}
            fontSize={'18px'}
            width={'40px'}
            height={'40px'}
            backgroundColor={'#F6A723'}
          >
            SF
          </Box>
        ),
        isImg: true,
        exact: true,
        component: Superfans,
      },
    ],
  },
  {
    name: '',
    exact: true,
    category: true,
    items: [
      {
        name: 'Settings',
        desc: 'Allows the superadmin to manage various configurations and preferences for Snapfood Platform',
        layout: '/admin',
        path: '/settings',
        exact: true,
        icon: Svg_settings,
        component: Settings,
      },
      {
        name: 'Dark Mode (TBD)',
        layout: '/admin',
        darkModeBtn: true,
        icon: Svg_moon,
      },
      {
        name: 'Log Out',
        layout: '/admin',
        path: '/logout',
        exact: true,
        icon: Svg_logout,
      },
    ],
  },
];

export default routes;
