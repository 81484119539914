import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Box
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'utils/common';
import './index.css';

export default function EventDetailsModal({ eventData, showModal, onClose }) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(showModal)
  }, [showModal])

  return (
    <>
      <Modal onClose={onClose} isOpen={visible} size={'2xl'} isCentered>
        <ModalOverlay />
        <ModalContent className={'custom-form event-detail-modal'}>
          <ModalHeader>Promotion Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody className='body'>
            <Box backgroundColor={'gray.200'} borderRadius={'12px'}
              p={'16px'} mb={'15px'}
            >
              <div className='label-data' style={{ marginTop: 0 }}>Title</div>
              <p>{eventData?.data_title}</p>
            </Box>
            <Box backgroundColor={'gray.200'} borderRadius={'12px'}
              p={'16px'} mb={'15px'}
            >
              <div className='label-data'>Type</div>
              <p>{eventData?.is_discout == 1 ? 'Discount' : 'Coupon'}</p>
            </Box>
            {
              !isEmpty(eventData?.description) &&
              <Box backgroundColor={'gray.200'} borderRadius={'12px'}
                p={'16px'} mb={'15px'}
              >
                <div className='label-data'>Description</div>
                <p>{eventData?.description}</p>
              </Box>
            }
            {
              !isEmpty(eventData?.promotion_details?.details) &&
              <Box backgroundColor={'gray.200'} borderRadius={'12px'}
                p={'16px'} mb={'15px'}
              >
                <div className='label-data'>Details</div>
                <p>{eventData?.promotion_details?.details}</p>
              </Box>
            }
            <HStack spacing={'20px'} >
              <Box backgroundColor={'gray.200'} borderRadius={'12px'}
                p={'16px'} flex={1}
              >
                <div className='label-data'>Start at</div>
                <p>{eventData?.start_time}</p>
              </Box>
              <Box backgroundColor={'gray.200'} borderRadius={'12px'}
                p={'16px'} flex={1}
              >
                <div className='label-data'>End at</div>
                <p>{eventData?.non_expired == 1 ? 'No Expiration' : eventData?.end_time}</p>
              </Box>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
