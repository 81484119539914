import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Flex, Button, useToast, Radio, Stack, Input, Alert, AlertIcon, Checkbox, Textarea } from "@chakra-ui/react";
import {
  AiOutlinePlus, AiOutlineSave
} from 'react-icons/ai';
import { useQuery } from "react-query";
import Card from 'components/card/Card';
import { PromotionsService } from "services/promotions";
import { isEmpty } from "utils/common";
import './index.css';

export default function PromoConfig() {
  const { status, data, error, isError, isLoading, refetch } = useQuery(
    ['promo-fetch-setting'],
    () => PromotionsService.getReminderSetting()
  );
  const toast = useToast();

  const [state, setState] = useState({});
  const [err_noti_msg, setNotiErrMsg] = useState('');
  const [err_email_msg, setEmailErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setState({
      enable_notification: data?.data?.settings?.enable_notification,
      enable_email: data?.data?.settings?.enable_email,
      nr_days_before_notification: data?.data?.settings?.nr_days_before_notification,
      nr_days_before_email: data?.data?.settings?.nr_days_before_email,
      nr_times_notification: data?.data?.settings?.nr_times_notification,
      nr_times_email: data?.data?.settings?.nr_times_email,
      noti_title: data?.data?.settings?.noti_title,
      noti_title_en: data?.data?.settings?.noti_title_en,
      noti_description: data?.data?.settings?.noti_description,
      noti_description_en: data?.data?.settings?.noti_description_en,
      email_title: data?.data?.settings?.email_title,
      email_title_en: data?.data?.settings?.email_title_en,
      email_description: data?.data?.settings?.email_description,
      email_description_en: data?.data?.settings?.email_description_en
    })
  }, [data?.data])

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    })
  }

  const onSave = () => {
    if (state.enable_notification == 1) {
      if (state.nr_days_before_notification == null || state.nr_times_notification == null ||
        isEmpty(state.noti_title) || isEmpty(state.noti_title_en) || isEmpty(state.noti_description) || isEmpty(state.noti_description_en)
      ) {
        setNotiErrMsg('Please fill all fields');
        return;
      }
    }
    if (state.enable_email == 1) {
      if (state.nr_days_before_email == null || state.nr_times_email == null ||
        isEmpty(state.email_title) || isEmpty(state.email_title_en) || isEmpty(state.email_description) || isEmpty(state.email_description_en)
      ) {
        setEmailErrMsg('Please fill all fields');
        return;
      }
    }

    setLoading(true);
    PromotionsService.updateReminderSetting(state)
      .then(({ data }) => {
        setLoading(false);
        setSuccessMsg('You updated setting successfully!');
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: `Something went wrong!`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      })
  }

  return (
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}
      className={'promo-config custom-form'}
    >
      <Card
        direction="column"
        w="100%"
        px="20px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <header style={{ paddingTop: 10, paddingBottom: 10, borderBottomWidth: 0 }}>Promotion Reminder</header>
        {
          !isEmpty(successMsg) && <Alert status='success'>
            <AlertIcon /> {successMsg}
          </Alert>
        }
        <Box w='100%' mt={4} p={4} borderRadius={'12px'} border={'1px'} borderColor={'gray.350'} borderStyle={'solid'} style={{ position: 'relative' }}>
          {
            !isEmpty(err_noti_msg) && <Alert status='error'>
              <AlertIcon /> {err_noti_msg}
            </Alert>
          }
          <SimpleGrid columns={2} spacing={10} pt={4}>
            <Checkbox isChecked={state?.enable_notification == 1} onChange={(e) => {
              handleChange('enable_notification', e.target.checked == true ? 1 : 0)
            }}>Enable Notification Reminder
            </Checkbox>
          </SimpleGrid>
          <div className='label'>Notification Title</div>
          <Input placeholder='Enter Title'
            value={state?.noti_title || ''}
            onChange={(e) => {
              handleChange('noti_title', e.target.value);
            }}
          />
          <div className='label'>Notification Title (English)</div>
          <Input placeholder='Enter Title (English)'
            value={state?.noti_title_en || ''}
            onChange={(e) => {
              handleChange('noti_title_en', e.target.value);
            }}
          />
          <div className='label'>Notification Description</div>
          <Textarea placeholder='Enter Description'
            value={state?.noti_description || ''}
            onChange={(e) => {
              handleChange('noti_description', e.target.value);
            }}
          />
          <div className='label'>Notification Description (English)</div>
          <Textarea placeholder='Enter Description (English)'
            value={state?.noti_description_en || ''}
            onChange={(e) => {
              handleChange('noti_description_en', e.target.value);
            }}
          />
          <SimpleGrid columns={2} spacing={10} pt={4}>
            <div>
              <div className='label'>Nr of days before remind</div>
              <Input placeholder='Nr of days before remind'
                type={'number'}
                value={state?.nr_days_before_notification || ''}
                onChange={(e) => {
                  handleChange('nr_days_before_notification', e.target.value);
                }}
              />
            </div>
            <div>
              <div className='label'>Nr of time to remind</div>
              <Input placeholder='Nr of time to remind'
                type={'number'}
                value={state?.nr_times_notification || ''}
                onChange={(e) => {
                  handleChange('nr_times_notification', e.target.value);
                }}
              />
            </div>
          </SimpleGrid>
        </Box>
        <Box w='100%' mt={4} p={4} borderRadius={'12px'} border={'1px'} borderColor={'gray.350'} borderStyle={'solid'} style={{ position: 'relative' }}>
          {
            !isEmpty(err_email_msg) && <Alert status='error'>
              <AlertIcon /> {err_email_msg}
            </Alert>
          }
          <SimpleGrid columns={2} spacing={10} pt={4}>
            <Checkbox isChecked={state?.enable_email == 1} onChange={(e) => {
              handleChange('enable_email', e.target.checked == true ? 1 : 0)
            }}>Enable Email Reminder
            </Checkbox>
          </SimpleGrid>
          <div className='label'>Email Subject</div>
          <Input placeholder='Enter Title'
            value={state?.email_title || ''}
            onChange={(e) => {
              handleChange('email_title', e.target.value);
            }}
          />
          <div className='label'>Email Subject (English)</div>
          <Input placeholder='Enter Title (English)'
            value={state?.email_title_en || ''}
            onChange={(e) => {
              handleChange('email_title_en', e.target.value);
            }}
          />
          <div className='label'>Email Content</div>
          <Textarea placeholder='Enter Description'
            value={state?.email_description || ''}
            onChange={(e) => {
              handleChange('email_description', e.target.value);
            }}
          />
          <div className='label'>Email Content (English)</div>
          <Textarea placeholder='Enter Description (English)'
            value={state?.email_description_en || ''}
            onChange={(e) => {
              handleChange('email_description_en', e.target.value);
            }}
          />
          <SimpleGrid columns={2} spacing={10} pt={4}>
            <div>
              <div className='label'>Nr of days before remind</div>
              <Input placeholder='Nr of days before remind'
                type={'number'}
                value={state?.nr_days_before_email || ''}
                onChange={(e) => {
                  handleChange('nr_days_before_email', e.target.value);
                }}
              />
            </div>
            <div>
              <div className='label'>Nr of time to remind</div>
              <Input placeholder='Nr of time to remind'
                type={'number'}
                value={state?.nr_times_email || ''}
                onChange={(e) => {
                  handleChange('nr_times_email', e.target.value);
                }}
              />
            </div>
          </SimpleGrid>
        </Box>
        <Flex mt={4} px="25px" justify="space-between" align="center">
          <div />
          <Button isLoading={loading} loadingText={'Saving...'} className={'app-main-btn'} w={'200px'} rightIcon={<AiOutlineSave />} onClick={onSave}>
            Save
          </Button>
        </Flex>
      </Card>
    </SimpleGrid>
  );
}
