import {
  Avatar, Box, Button, Flex, Text, useColorModeValue, Image
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import React from 'react';
import { getImageFullURL } from 'utils/common';
import Svg_dots from 'assets/img/icons/dots-vertical.svg'
import { Fonts } from 'theme/theme';
import { isEmpty } from 'utils/common';

export default function UserInfo(props) {
  const { data } = props;
  // Chakra Color Mode
  const bgColor = useColorModeValue('gray.150', 'whiteAlpha.100');
  const nameColor = useColorModeValue('textColor.0', 'white');
  const unameColor = useColorModeValue('textColor.3', 'white');

  return (
    <Card mb={{ base: '0px', lg: '20px' }} p={{ base: '16px', md: '24px', lg: '32px' }} align="center">
      <Flex width={'100%'} justifyContent={'space-between'}>
        <Flex justifyContent={'center'} alignItems={'center'}>
          <Avatar
            src={getImageFullURL(data?.photo)}
            width={'64px'} height={'64px'}
          />
          <Flex direction={'column'} ms={'16px'} justifyContent='flex-start' alignItems={'flex-start'}>
            <Text fontSize={'22px'} fontFamily={Fonts.bold} color={nameColor}>{data?.full_name}</Text>
            <Text fontSize={'16px'} fontFamily={Fonts.medium} color={isEmpty(data?.username) ? '#f55a00' : unameColor}>@{isEmpty(data?.username) ? ' - no username -' : data?.username}</Text>
          </Flex>
        </Flex>
        <Image src={Svg_dots} width={'24px'} height={'24px'} />
      </Flex>
      <Flex
        mt={{ base: '16px', md: '20px', lg: '32px' }}
        px={{ base: '16px', md: '24px', lg: '32px' }}
        py={{ base: '10px', md: '12px', lg: '16px' }}
        align="center"
        width={'100%'} justifyContent={'space-evenly'}
        bgColor={bgColor}
        borderRadius={12}
      >
        <Flex direction={'column'} ms={'16px'} flex={1} justifyContent='center' alignItems={'center'}>
          <Text fontSize={'14px'} fontFamily={Fonts.medium} color={unameColor}>Email Address</Text>
          <Text fontSize={'16px'} fontFamily={Fonts.semibold} color={nameColor}>{data?.email}</Text>
        </Flex>
        <Flex direction={'column'} ms={'16px'} flex={1} justifyContent='center' alignItems={'center'}>
          <Text fontSize={'14px'} fontFamily={Fonts.medium} color={unameColor}>Phone Number</Text>
          <Text fontSize={'16px'} fontFamily={Fonts.semibold} color={nameColor}>{data?.phone}</Text>
        </Flex>
        <Flex direction={'column'} ms={'16px'} flex={1} justifyContent='center' alignItems={'center'}>
          <Text fontSize={'14px'} fontFamily={Fonts.medium} color={unameColor}>Country</Text>
          <Text fontSize={'16px'} fontFamily={Fonts.semibold} color={nameColor}>{data?.country}</Text>
        </Flex>
        <Flex direction={'column'} ms={'16px'} flex={1} justifyContent='center' alignItems={'center'}>
          <Text fontSize={'14px'} fontFamily={Fonts.medium} color={unameColor}>Source</Text>
          <Text fontSize={'16px'} fontFamily={Fonts.semibold} color={nameColor}>{data?.source}</Text>
        </Flex>
      </Flex>
    </Card>
  );
}
