import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Flex, Button, useToast, Radio, Stack, Input, Alert, AlertIcon, Checkbox, Textarea } from "@chakra-ui/react";
import {
  AiOutlinePlus, AiOutlineSave
} from 'react-icons/ai';
import { useQuery } from "react-query";
import Card from 'components/card/Card';
import { PromotionsService } from "services/promotions";
import { SettingsService } from 'services/settings'
import { isEmpty } from "utils/common";
import './index.css';

export default function SystemConfig() {
  const toast = useToast();

  const [state, setState] = useState({});
  const [err_noti_msg, setNotiErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const handleChange = (name, value) => {
    setHasChanges(true);
    setState({
      ...state,
      [name]: value
    })
  }

  useEffect(() => {
    loadSetting();
  }, [])

  const loadSetting = () => {
    SettingsService.get()
      .then(({ data }) => {
        setState(data?.settings);
      })
      .catch((err) => {
      })
  }

  const onSave = () => {
    if (state.gift_order_discount > 99) {
      return setNotiErrMsg('Invalid order discount amount');
    }
    setNotiErrMsg('');
    SettingsService.update({ settings: { gift_order_discount: state.gift_order_discount, cart_noti_hours: state.cart_noti_hours } })
      .then(({ data }) => {
        setHasChanges(false);
      })
      .catch((err) => {
        setNotiErrMsg(err.message || 'Something went wrong!')
      })
  }

  return (
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}
      className={'promo-config custom-form'}
    >
      <Card
        direction="column"
        w="100%"
        px="20px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <header style={{ paddingTop: 10, paddingBottom: 10, borderBottomWidth: 0 }}>System Configuration</header>
        {
          !isEmpty(successMsg) && <Alert status='success'>
            <AlertIcon /> {successMsg}
          </Alert>
        }
        <Box w='100%' mt={4} p={4} borderRadius={'12px'} border={'1px'} borderColor={'gray.350'} borderStyle={'solid'} style={{ position: 'relative' }}>
          {
            !isEmpty(err_noti_msg) && <Alert status='error'>
              <AlertIcon /> {err_noti_msg}
            </Alert>
          }
          <div className='label'>Gift Order Discount (%)</div>
          <Input placeholder='Enter Gift order discount (%)'
            value={state?.gift_order_discount}
            onChange={(e) => {
              handleChange('gift_order_discount', e.target.value);
            }}
            type="number"
          />
          <div className='label'>Unused Cart Notification Interval (hours)</div>
          <Input placeholder='Unused Cart Notification Interval (hours)'
            value={state?.cart_noti_hours}
            onChange={(e) => {
              handleChange('cart_noti_hours', e.target.value);
            }}
            type="number"
          />
        </Box>
        <Flex mt={4} px="25px" justify="space-between" align="center">
          <div />
          <Button disabled={!hasChanges} isLoading={loading} loadingText={'Saving...'} className={'app-main-btn'} w={'200px'} rightIcon={<AiOutlineSave />} onClick={onSave}>
            Save
          </Button>
        </Flex>
      </Card>
    </SimpleGrid>
  );
}
