import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import routes from 'routes.js';

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const navbarPosition = 'fixed';
  const navbarShadow = 'none';
  const navbarBg = useColorModeValue(
    'white',
    'navy.900',
  );
  const navbarBorder = 'transparent';
  const secondaryMargin = '0px';
  const paddingX = '15px';
  const gap = '0px';
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      borderWidth="1.5px"
      borderStyle="solid"
      alignItems={'center'}
      display={'flex'}
      minH="88px"
      justifyContent={'center'}
      lineHeight="25.6px"
      zIndex={1000}
      mx="auto"
      mt={secondaryMargin}
      right={'0px'}
      px={{
        sm: paddingX,
        md: '10px',
      }}
      ps={{
        xl: '12px',
      }}
      pt="0px"
      top={'0px'}
      w={{
        base: '100%',
        md: '100%',
        lg: '100%',
        xl: 'calc(100% - 250px)',
        '2xl': 'calc(100% - 250px)',
      }}
      borderBottom={'1px solid #F1F5F9'}
      borderLeft={'1px solid #F1F5F9'}
    >
      <Flex
        w="100%"
        flexDirection={'row'}
        alignItems={'center'}
        mb={gap}
      >
        <SidebarResponsive routes={routes} />
        <Box ms="auto" justifyContent={'flex-end'}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            fixed={props.fixed}
            scrolled={scrolled}
          />
        </Box>
      </Flex>
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
