export const columnsDataFeedbacks = [
  {
    Header: 'No',
    accessor: 'id',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Title (English)',
    accessor: 'title_en',
  },
  {
    Header: 'Type',
    accessor: 'category',
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
