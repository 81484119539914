import React from 'react';
import { Box, HStack, Stack, Grid, GridItem, SimpleGrid } from '@chakra-ui/react';
import {
  TopVendors, TopCustomers, OrdersBySource, OrdersByHours, Revenue
} from 'components/statistics/orders';
import { FcGrid } from 'react-icons/fc';

export default function OrdersStats() {
  return (
    <Box w="100%" >
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w={'100%'}
        gap={'20px'}
      >
        <Box w={{ base: '100%', md: '67%' }}>
          <Revenue />
        </Box>
        <Box w={{ base: '100%', md: '33%' }}>
          <OrdersBySource />
        </Box>
      </Stack>
      <Box w="100%" mt={5}>
        <OrdersByHours />
      </Box>
      <HStack mt={5} spacing={'20px'}>
        <Box w="100%">
          <TopVendors />
        </Box>
        <Box w="100%" mt={5}>
          <TopCustomers />
        </Box>
      </HStack>
    </Box>
  );
}
