import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Box,
  SimpleGrid,
  Input,
  Textarea,
  CloseButton,
  Alert,
  AlertIcon,
  useToast,
  Checkbox
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'utils/common';
import './index.css';
import { InterestsService } from 'services/interests'

export default function CreateModal({ showModal, item, onClose, onReload }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [data, setData] = useState({
    title: '',
    title_en: '',
    category: 'food'
  });

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value
    })
  }

  useEffect(() => {
    setVisible(showModal);
    if (showModal) {
      if (item) {
        setData(item)
      }
      else {
        setData({
          title: '',
          title_en: '',
          category: 'food'
        })
      }
    }
  }, [showModal])

  const onSave = () => {
    if (isEmpty(data.title) ||
      isEmpty(data.title_en) ||
      isEmpty(data.category)
    ) {
      setMessage('Please fill all fields!');
      return;
    }

    data.id = item?.id;

    setLoading(true);
    InterestsService.createInterest(data)
      .then(({ data }) => {
        setLoading(false);
        onReload();
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: `Something went wrong!`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      })
  }

  return (
    <>
      <Modal onClose={onClose} isOpen={visible} size={'2xl'} isCentered scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent className={'custom-form create-noti-modal'}>
          <ModalHeader>New Interest</ModalHeader>
          <ModalCloseButton />
          <ModalBody className='body'>
            <Box w='100%' style={{ position: 'relative' }}>
              {
                !isEmpty(message) && <Alert status='error'>
                  <AlertIcon /> {message}
                </Alert>
              }
              <div className='label'>Title</div>
              <Input placeholder='Enter Title'
                value={data?.title || ''}
                onChange={(e) => {
                  handleChange('title', e.target.value);
                }}
              />
              <div className='label'>Title (English)</div>
              <Input placeholder='Enter Title (English)'
                value={data?.title_en || ''}
                onChange={(e) => {
                  handleChange('title_en', e.target.value);
                }}
              />
              <div className='label'>Type</div>
              <Select placeholder='Select Type'
                value={data?.category}
                onChange={(e) => {
                  handleChange('category', e.target.value);
                }}
              >
                <option value='food'>Food</option>
                <option value='social'>Social</option>
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} className='cancel'>Close</Button>
            <Box width={'16px'} />
            <Button onClick={onSave} className='ok' isLoading={loading} loadingText={'Saving...'}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
