import React from 'react';

import { Box, Text, Image, useColorModeValue } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import Snapfoodlogo from 'assets/img/logo.png';
import { Fonts } from 'theme/theme';


export function SidebarBrand({ps, showBorder = true }) {
  const logoColor = useColorModeValue('textColor.0', 'white')
  return (
    <Box>
      <Box height={'88px'}
        display='flex'
        justifyContent={'flex-start'}
        alignItems='center'
        borderBottom={showBorder ? '1px solid #F1F5F9' : ''}
        ps={ps ?? '32px'}
      >
        <Image src={Snapfoodlogo} height="28px" me={'9px'} borderRadius={'6px'}/>
        <Text color={logoColor} fontSize={24} fontFamily={Fonts.bold}>Superadmin</Text>
      </Box>
    </Box>
  );
}

export default SidebarBrand;
