import { useQuery } from 'react-query';
import { OrderStatisticsService } from 'services/statistics';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue
} from '@chakra-ui/react';
import { CardChart } from 'components/statistics/components/CardChart';
import { useSaveQueryParams } from 'hooks/use-save-query-params';
import Card from 'components/card/Card';
import { CirclesWithBar } from 'react-loader-spinner';

export const TopVendors = (props) => {
  const { queryParams, onSave } = useSaveQueryParams();
  const textColor = useColorModeValue('textColor.0', 'white');
  const thColor = useColorModeValue('textColor.3', 'white');
  const theadBgColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const { status, data, error, isError, isLoading } = useQuery(
    ['getTopVendors', queryParams],
    () => OrderStatisticsService.getTopVendors(queryParams)
  );

  if (isLoading) {
    return <Card align="center" w="100%" h="20rem" justify="center">
      <CirclesWithBar
        height="80"
        width="80"
        color="#22adc4"
        ariaLabel="bars-loading"
        wrapperStyle={{ margin: 'auto' }}
        wrapperClass=""
        visible={true}
      />
    </Card>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  function DataTable() {
    return (
      <Table variant="simple" className={'custom-table'}>
        <Thead backgroundColor={theadBgColor}>
          <Tr>
            <Th color={thColor}>Id</Th>
            <Th color={thColor}>Name</Th>
            <Th color={thColor} isNumeric>Nr. Of Orders</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.data.filter((d) => !!d.title).map((d, i) => (
            <Tr key={i}>
              <Td color={textColor}>{d.id}</Td>
              <Td color={textColor}>{d.title}</Td>
              <Td color={textColor} isNumeric>{d.nr_of_orders}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  return <CardChart
    title="Top 30 Vendors"
    chartData={[]}
    categories={{}}
    isLoading={isLoading}
    CustomChart={DataTable}
    onSave={onSave}
  />;
};
