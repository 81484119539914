import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Flex,
  Stack,
  HStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'utils/common';
import './index.css';

export default function EventDetailsModal({ eventData, showModal, onClose }) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(showModal)
  }, [showModal])

  return (
    <>
      <Modal onClose={onClose} isOpen={visible} size={'2xl'} isCentered>
        <ModalOverlay />
        <ModalContent className={'custom-form event-detail-modal'}>
          <ModalHeader>Notification Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody className='body'>
            <Box backgroundColor={'gray.200'} borderRadius={'12px'}
              p={'16px'} mb={'15px'}
            >
              <div className='label-data' >Type</div>
              <p>{eventData?.type}</p>
            </Box>
            <Box backgroundColor={'gray.200'} borderRadius={'12px'}
              p={'16px'} mb={'15px'}
            >
              <div className='label-data' >Title</div>
              <p>{eventData?.title}</p>
            </Box>
            <Box backgroundColor={'gray.200'} borderRadius={'12px'}
              p={'16px'} mb={'15px'}
            >
              <div className='label-data' >Title (English)</div>
              <p>{eventData?.title_en}</p>
            </Box>
            <Box backgroundColor={'gray.200'} borderRadius={'12px'}
              p={'16px'} mb={'15px'}
            >
              <div className='label-data' >Description</div>
              <p>{eventData?.description}</p>
            </Box>
            <Box backgroundColor={'gray.200'} borderRadius={'12px'}
              p={'16px'} mb={'15px'}
            >
              <div className='label-data' >Description (English)</div>
              <p>{eventData?.description_en}</p>
            </Box>
            {
              !isEmpty(eventData?.vendor_data?.title) &&
              <Box backgroundColor={'gray.200'} borderRadius={'12px'}
                p={'16px'} mb={'15px'}
              >
                <div className='label-data' >Vendor</div>
                <p>{eventData?.vendor_data?.title}</p>
              </Box>
            }
            {
              eventData?.is_sent == 1 ?
                <HStack spacing={'20px'} >
                  <Box backgroundColor={'gray.200'} borderRadius={'12px'}
                    p={'16px'} flex={1}
                  >
                    <div className='label-data'>Sent at</div>
                    <p>{eventData?.created_at}</p>
                  </Box>
                  <Box backgroundColor={'gray.200'} borderRadius={'12px'}
                    p={'16px'} flex={1}
                  >
                    <div className='label-data'>Total Sent Count</div>
                    <p>{eventData?.count}</p>
                  </Box>
                </HStack>
                :
                <Box backgroundColor={'gray.200'} borderRadius={'12px'}
                  p={'16px'} 
                >
                  <div className='label-data' >Scheduled at</div>
                  <p>{eventData?.schedule_time}</p>
                </Box>
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
