import { boostFactory } from '../index';

const PREFIX = '/vendor-contact-applications';

const API_LIST = {
  FETCH_ALL: `${PREFIX}`,
  UPDATE: `${PREFIX}`
};

export const VendorApplicationService = {
  get(searQuery = '') {
    return boostFactory.get(`${API_LIST.FETCH_ALL}${searQuery}`);
  },
  update(id, data) {
    return boostFactory.put(`${API_LIST.UPDATE}/${id}`, data);
  },
};
