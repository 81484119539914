export const columnsData = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Full Name',
    accessor: 'full_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  }, 
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Total Count',
    accessor: 'referral_cnt',
  },
];
