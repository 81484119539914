import AuthService from '../../services/apiAuth';
import { ApiFactory, Storage } from '../../services';
import { ACTION_TYPES } from '../../constants';
import { getWebPushToken } from '../../services/pushWeb';

const getLoggedInUserData = () => new Promise((resolve, reject) => {
  try {
    AuthService.getLoggedInUserData()
      .then((res) => {
        resolve(res.data.user);
      })
      .catch((err) => {
        reject(err);
      });
  } catch (e) {
    reject(e);
  }
});

export const getLoggedInUser = () => (dispatch) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve) => {
    try {
      const user = await getLoggedInUserData();
      dispatch({
        type: ACTION_TYPES.APP_SET_USER_DATA,
        payload: user,
      });
      dispatch({
        type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
        payload: !!user.verified_by_mobile,
      });
      resolve(user);
    } catch (e) {
      resolve();
    }
  });
export const login = ({ email, password }) => (dispatch) => new Promise((resolve, reject) => {
  try {
    const device = {
      token: '28214935f40078a5097038da924e08d37e3f04c2419045ae61dceddaa64fd262',
      uuid: '1773315E-CD81-4869-82C4-50DD15610945',
      mode: 'iPhone10,5',
      platform: 'iOS',
      version: '11.2'
    };
    AuthService.emailLogin({ email, password, device }).then(
      async (response) => {
        const { token, verified_by_mobile } = response.data;
        Storage.setAppToken(token);
        const user = await getLoggedInUserData();

        dispatch({
          type: ACTION_TYPES.APP_SET_USER_DATA,
          payload: user,
        });

        resolve(user);
      },
      async (e) => {
        reject(e);
      },
    );
  } catch (e) {
    reject(e);
  }
});

export const setAsLoggedIn = () => async (dispatch) => new Promise((resolve, reject) => {
  try {
    dispatch({
      type: ACTION_TYPES.APP_LOGGED_IN,
      payload: true,
    });
    resolve();
  } catch (e) {
    reject(e);
  }
});

export const legacyLogin = (token) => async (dispatch) => new Promise((resolve, reject) => {
  try {
    const device = {
      token: getWebPushToken(),
    };

    ApiFactory.post(
      'login/legacy',
      { device },
      {
        headers: {
          Authorization: token,
        },
      },
    ).then(
      async (response) => {
        const { token } = response.data;
        Storage.setAppToken(token);

        const user = await getLoggedInUserData();
        dispatch({
          type: ACTION_TYPES.APP_SET_USER_DATA,
          payload: user,
        });

        resolve(user);
      },
      async (e) => {
        reject(e);
      },
    );
  } catch (e) {
    reject(e);
  }
});

export const logout = () => async (dispatch) => {
  try {
    await ApiFactory.get('logout');
  } catch (e) {
  }
  Storage.clearAppToken();
  dispatch({
    type: ACTION_TYPES.APP_LOGGED_IN,
    payload: false,
  });
  dispatch({
    type: ACTION_TYPES.APP_SET_USER_DATA,
    payload: {},
  });
};
