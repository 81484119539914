import Card from 'components/card/Card.js';
import { useQuery } from "react-query";
import React from 'react';
import { UsersService } from 'services/users'
import { SimpleGrid, Box } from '@chakra-ui/react';
import SuperFanItem from './components/SuperFanItem';

export default function SuperFans(props) {
  const { status, data, error, isError, isLoading, refetch } = useQuery(
    ['superfans-fetch-all'],
    () => UsersService.getSuperfans()
  );

  return (
    <Card p='20px' align='center' direction='column' w='100%' h={'calc(100vh - 200px)'}>
      <SimpleGrid minChildWidth='350px' spacing='24px'>
        {
          data?.data?.superfans?.map(item =>
            <SuperFanItem
              key={item.id}
              data={item}
            />
          )
        }
      </SimpleGrid>
    </Card>
  );
}
