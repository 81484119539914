import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Box,
  SimpleGrid,
  Input,
  Textarea,
  CloseButton,
  Alert,
  AlertIcon,
  useToast,
  Checkbox
} from '@chakra-ui/react'
import VendorSelect from 'components/fields/VendorSelect';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { isEmpty } from 'utils/common';
import './index.css';
import { NotificationService } from 'services/notification';
import moment from 'moment';
import CustomDatePicker from 'components/fields/CustomDatePicker';

const FormNoti = ({ data, onChange, onRemove }) => {
  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value
    })
  }

  return (
    <Box w='100%' mt={2} p={4} border={'1px solid'} borderRadius={'12px'} borderColor={'gray.350'} style={{ position: 'relative' }}>
      {
        !isEmpty(data?.message) && <Alert status='error'>
          <AlertIcon /> {data?.message}
        </Alert>
      }
      <div className='label'>Title</div>
      <Input placeholder='Enter Title'
        value={data?.title || ''}
        onChange={(e) => {
          handleChange('title', e.target.value);
        }}
      />
      <div className='label'>Title (English)</div>
      <Input placeholder='Enter Title (English)'
        value={data?.title_en || ''}
        onChange={(e) => {
          handleChange('title_en', e.target.value);
        }}
      />
      <div className='label'>Description</div>
      <Textarea placeholder='Enter Description'
        value={data?.description || ''}
        onChange={(e) => {
          handleChange('description', e.target.value);
        }}
      />
      <div className='label'>Description (English)</div>
      <Textarea placeholder='Enter Description (English)'
        value={data?.description_en || ''}
        onChange={(e) => {
          handleChange('description_en', e.target.value);
        }}
      />
      <SimpleGrid columns={2} spacing={10} pt={4}>
        <Select placeholder='Select Type'
          value={data?.type}
          onChange={(e) => {
            onChange({
              ...data,
              'type': e.target.value,
              'vendor': null,
            })
          }}
        >
          <option value='general_notification'>General</option>
          <option value='30_no_order_notification'>30days No Order</option>
          <option value='vendor_notification'>Vendor</option>
          <option value='general_referral_notification'>Referral</option>
          <option value='general_earn_notification'>Earn</option>
          <option value='general_cashback_notification'>Cashback</option>
          <option value='from_superadmin_to_map'>Redirection to Map</option>
          <option value='from_superadmin_to_promotions'>Redirection to Promotions</option>
          <option value='from_superadmin_to_promotion_calendar'>Redirection to Calendar</option>
          <option value='from_superadmin_to_student_acccess'>Redirection to Student Access</option>
          <option value='from_superadmin_to_favourites'>Redirection to Favourites</option>
          <option value='from_superadmin_to_payment_methods'>Redirection to Payment methods</option>
          <option value='from_superadmin_to_profile'>Redirection to Profile</option>
          <option value='from_superadmin_to_friends'>Redirection to Friends</option>
        </Select>
        <VendorSelect
          isDisabled={data?.type != 'vendor_notification'}
          value={data?.vendor}
          onChange={(v) => {
            handleChange('vendor', v);
          }}
        />
      </SimpleGrid>
      <SimpleGrid columns={2} spacing={10} pt={4}>
        <Checkbox value={data?.is_schedule == true} onChange={(e) => {
          onChange({
            ...data,
            'is_schedule': e.target.checked,
            'schedule_date': (e.target.checked == false ? null : (data?.schedule_date ?? new Date())),
          })
        }}>is Schedule?
        </Checkbox>
        <CustomDatePicker
          is_schedule={data?.is_schedule}
          schedule_date={data?.schedule_date}
          onChange={(date) => {
            handleChange('schedule_date', date);
          }}
        />
      </SimpleGrid>
      <CloseButton onClick={() => onRemove()} style={{ position: 'absolute', top: 6, right: 6 }} />
    </Box>
  )
}

export default function CreateNotiModal({ showModal, onClose, onReload }) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [notiForms, setNotiForms] = useState([{
    id: new Date().getTime(),
  }])
  useEffect(() => {
    setVisible(showModal);
    if (showModal) {
      setNotiForms([{
        id: new Date().getTime(),
      }])
    }
  }, [showModal])

  const onSave = () => {
    console.log('notiForms ', notiForms);
    let hasError = false;
    let tmp = notiForms.slice(0);
    for (let i = 0; i < notiForms.length; i++) {
      if (isEmpty(notiForms[i].title) ||
        isEmpty(notiForms[i].title_en) ||
        isEmpty(notiForms[i].description) ||
        isEmpty(notiForms[i].description_en) ||
        isEmpty(notiForms[i].type) ||
        (
          notiForms[i].type == 'vendor_notification' &&
          notiForms[i].vendor?.value == null
        ) ||
        (
          notiForms[i].is_schedule == true &&
          notiForms[i].schedule_date == null
        )
      ) {
        tmp[i] = { ...notiForms[i], message: 'Please fill all fields!' }
        hasError = true;
      }
    }

    if (hasError == true) {
      setNotiForms(tmp);
      return;
    }

    tmp = notiForms.map(item => ({
      ...item, vendor: null, vendor_id: item.vendor?.value,
      schedule_date: (item.is_schedule == true ? (moment(item.schedule_date).format('YYYY-MM-DD HH:mm') + ':00') : null)
    }))
    console.log('payload ', tmp);

    setLoading(true);
    NotificationService.createNotification({ notifications: tmp })
      .then(({ data }) => {
        setLoading(false);
        onReload();
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: `Something went wrong!`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      })
  }

  return (
    <>
      <Modal onClose={onClose} isOpen={visible} size={'2xl'} isCentered scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent className={'custom-form create-noti-modal'}>
          <ModalHeader>New Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody className='body'>
            {
              notiForms.map(item =>
                <FormNoti key={item.id} data={item}
                  onChange={(v) => {
                    let tmp = notiForms.slice(0);
                    let index = tmp.findIndex(i => i.id == v.id);
                    if (index != -1) {
                      tmp[index] = { ...v, message: '' }
                      setNotiForms(tmp);
                    }
                  }}
                  onRemove={() => {
                    if (notiForms.length > 1) {
                      let tmp = notiForms.slice(0);
                      let index = tmp.findIndex(i => i.id == item.id);
                      if (index != -1) {
                        tmp.splice(index, 1)
                        setNotiForms(tmp);
                      }
                    }
                  }}
                />
              )
            }
            <Button mt={'24px'} className={'noti-form-add'} rightIcon={<AiOutlinePlus />} onClick={() => {
              let tmp = notiForms.slice(0);
              tmp.push({
                id: new Date().getTime()
              })
              setNotiForms(tmp);
            }}>
              Add More
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} className='cancel'>Close</Button>
            <Box width={'16px'} />
            <Button onClick={onSave} className='ok' isLoading={loading} loadingText={'Saving...'}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
