import React from 'react';
import { Box, HStack, Stack } from '@chakra-ui/react';
import {
  TotalOrdersDiscounts,
  TotalOrdersCoupons,
  TotalAmountCoupons,
  TotalAmountDiscounts,
  TotalOrdersPromotions,
  TotalAmountPromotions,
  TotalAmountEarnedCashback,
  TotalAmountUsedCashback,
  MostUsedValues,
  ActivePromotions,
} from 'components/statistics/food';

export default function PromoStatistics() {
  return (
    <Box w="100%" >
      <HStack spacing={5}>
        <Box w="100%">
          <TotalOrdersCoupons />
        </Box>
        <Box w="100%">
          <TotalAmountCoupons />
        </Box>
      </HStack>
      <HStack spacing={5} mt={5}>
        <Box w="100%">
          <TotalOrdersDiscounts />
        </Box>
        <Box w="100%">
          <TotalAmountDiscounts />
        </Box>
      </HStack>
      <HStack spacing={5} mt={5}>
        <Box w="100%">
          <TotalOrdersPromotions />
        </Box>
        <Box w="100%">
          <TotalAmountPromotions />
        </Box>
      </HStack>
      <HStack spacing={5} mt={5}>
        <Box w="100%">
          <TotalAmountEarnedCashback />
        </Box>
        <Box w="100%">
          <TotalAmountUsedCashback />
        </Box>
      </HStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w={'100%'}
        gap={'20px'}
        mt={5}
      >
        <Box w={{ base: '100%', md: '50%' }}>
          <MostUsedValues />
        </Box>
        <Box w={{ base: '100%', md: '50%' }}>
          <ActivePromotions />
        </Box>
      </Stack>
    </Box>
  );
}
