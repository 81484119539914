import React, { useState } from "react";
import { Box, SimpleGrid, Flex, Button, RadioGroup, Radio, Stack, Image } from "@chakra-ui/react";
import {
  AiOutlinePlus
} from 'react-icons/ai';
import { useQuery } from "react-query";
import Card from 'components/card/Card';
import ListDatatable from "./components/ListDatatable";
import {
  columnsDataFeedbacks,
} from "./variables/columnsData";
import { NotificationService } from 'services/notification'
import HCalendar from "./components/HCalendar";
import CreateNotiModal from "./details/CreateModal";
import SwitchTab from "./components/SwitchTab";

export default function Notifications() {
  const { status, data, error, isError, isLoading, refetch } = useQuery(
    ['notifications-fetch-all'],
    () => NotificationService.getAllNotifications()
  );
  const [mode, setMode] = useState('table');
  const [isCreateModal, showCreateModal] = useState(false);

  return (
    <>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <Card
          direction="column"
          w="100%"
          px="24px"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
            <Button className="app-main-btn" mt={'20px'} rightIcon={<AiOutlinePlus />} onClick={() => showCreateModal(true)}>
              New Notification
            </Button>
            <SwitchTab
              onChange={setMode} mode={mode} style={{ marginTop: '20px' }}
            />
          </Flex>
          {
            mode == 'table' ?
              <ListDatatable
                data={data?.data}
                columnsData={columnsDataFeedbacks}
              />
              :
              <HCalendar data={data?.data} />
          }
        </Card>
      </SimpleGrid>
      <CreateNotiModal
        showModal={isCreateModal}
        onClose={() => showCreateModal(false)}
        onReload={() => {
          showCreateModal(false)
          refetch()
        }}
      />
    </>
  );
}
