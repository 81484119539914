import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Flex, Grid, RadioGroup, Radio, Text, Image, GridItem } from "@chakra-ui/react";
import CustomDateRangePicker from "components/dateFilter/DateRangePicker";
import { ReactComponent as SvgIcon1 } from 'assets/img/icons/sales_icon1.svg'
import { ReactComponent as SvgIcon2 } from 'assets/img/icons/sales_icon2.svg'
import { ReactComponent as SvgIcon3 } from 'assets/img/icons/sales_icon3.svg'
import { ReactComponent as SvgIcon4 } from 'assets/img/icons/sales_icon4.svg'
import { ReactComponent as SvgAlert } from 'assets/img/icons/alert-circle.svg'
import WalletAmountBox from "./components/WalletAmountBox";
import CustomersAmountBox from "./components/CustomersAmountBox";
import { useSaveQueryParams } from 'hooks/use-save-query-params';
import { WalletStatisticsService } from "services/statistics";

export default function WalletStats() {
  const { queryParams, onSave } = useSaveQueryParams();
  const [creditData, setCreditData] = useState(null);
  const [customersData, setCustomersData] = useState(null);

  useEffect(() => {
    WalletStatisticsService.getCredits(queryParams)
      .then(({ data }) => {
        setCreditData(data);
      })
      .catch((error) => {
        console.log('getCredits error', error)
      });

    WalletStatisticsService.getCustomers(queryParams)
      .then(({ data }) => {
        setCustomersData(data);
      })
      .catch((error) => {
        console.log('getCustomers error', error)
      });
  }, [queryParams])

  return (
    <SimpleGrid
      mt={'20px'}
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
      <Flex mt={'20px'} justify={'flex-start'} align={'center'}>
        <Text mr={'10px'} fontSize={'14px'} lineHeight={'150%'} color={'textColor.0'} fontWeight={'semibold'}>Customer Related</Text>
        <SvgAlert />
      </Flex>
      <Box
        p={{ base: '16px', md: '24px' }}
        w={'100%'}
        bgColor={'#fff'}
        borderRadius={'12px'}
      >
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={{ base: '16px', md: '24px' }} >
          <CustomersAmountBox
            label={'Total customers'}
            desc={'who have credit in wallet'}
            value={customersData?.total_customers_has_wallet}
          />
          <CustomersAmountBox
            label={'Total customers'}
            desc={'who have credit in wallet, but never used'}
            value={customersData?.total_customers_has_wallet_never_used}
          />
          <GridItem />
          <GridItem />
        </Grid>
      </Box>
      <Flex mt={'20px'} justify={'flex-start'} align={'center'}>
        <Text mr={'10px'} fontSize={'14px'} lineHeight={'150%'} color={'textColor.0'} fontWeight={'semibold'}>Credit Related</Text>
        <SvgAlert />
      </Flex>
      <Flex direction={'row'} justify='flex-start' align="center">
        <CustomDateRangePicker onSave={onSave} />
      </Flex>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' }} gap={{ base: '14px', md: '24px' }} >
        <WalletAmountBox
          icon={<SvgIcon1 />}
          label={'Total transfers'}
          value={creditData?.total_transfers}
        />
        <WalletAmountBox
          icon={<SvgIcon2 />}
          label={'Total deposit'}
          value={creditData?.total_deposit}
        />
        <WalletAmountBox
          icon={<SvgIcon3 />}
          label={'Total earning from referral'}
          value={creditData?.total_referral}
        />
        <WalletAmountBox
          icon={<SvgIcon4 />}
          label={'Total earning from earn order'}
          value={creditData?.total_earnorder}
        />
        <WalletAmountBox
          icon={<SvgIcon1 />}
          label={'Total bonus'}
          value={creditData?.total_bonus}
        />
      </Grid>
    </SimpleGrid>
  );
}
