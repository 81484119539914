import { useState } from 'react';

// Get Last week date range start_date=YYYY-MM-DD&end_date=YYYY-MM-DD
const getLastWeekDateRange = () => {
  const today = new Date();
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  const start_date = lastWeek.toISOString().split('T')[0];
  const end_date = today.toISOString().split('T')[0];
  return { start_date, end_date };
};

export function useSaveQueryParams() {
  const { start_date, end_date } = getLastWeekDateRange();
  const [queryParams, setQueryParams] = useState(`?start_date=${start_date}&end_date=${end_date}`);
  const onSave = (date) => {
    setQueryParams(`?start_date=${date.startDate}&end_date=${date.endDate}`);
  };
  return {
    queryParams,
    onSave
  };
}
