import { ApiFactory } from '../index';

const PREFIX = '/superadmin/promotions';

const API_LIST = {
  FETCH_ALL: `${PREFIX}`,
  REMINDER_SETTING: `${PREFIX}/reminder-setting`,
};

export const PromotionsService = {
  getPromotions(searQuery = '') {
    return ApiFactory.get(`${API_LIST.FETCH_ALL}${searQuery}`);
  },
  getReminderSetting(searQuery = '') {
    return ApiFactory.get(`${API_LIST.REMINDER_SETTING}${searQuery}`);
  },
  updateReminderSetting(data) {
    return ApiFactory.post(API_LIST.REMINDER_SETTING, data);
  },
};
