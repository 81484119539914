import React, { useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card.js';
import { Box, Button, Flex, Input, SimpleGrid } from '@chakra-ui/react';
import { columnsDataQuiz } from '../reports/variables/quizColumns';
import CommonDataGrid from '../dataTables/components/CommonDataGrid';
import { AiOutlineSave } from 'react-icons/ai';
import { quizzService } from 'services/quiz';
import Loader from 'components/eventsMarketingCalendar/miniComponents/Loader';

const initialValues = {
  wordcount: '',
  max_earn: '',
  earn_per_correct_answer: '',
};

export default function Quiz() {
  const [quizDetails, setQuizDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [quizzData, setQuizzData] = useState({
    quizzes: [],
    configuration: null,
  });

  const handleChange = (key, e) => {
    setQuizDetails((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const payload = useMemo(() => {
    if (quizDetails) {
      return {
        wordcount: Number(quizDetails.wordcount),
        max_earn: Number(quizDetails.max_earn),
        earn_per_correct_answer: Number(quizDetails.earn_per_correct_answer),
      };
    }
    return initialValues;
  }, [quizDetails]);

  const getAllQuizzes = () => {
    setLoading(true);

    quizzService
      .getQuizzes()
      .then((res) => {
        setQuizzData(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => getAllQuizzes(), []);
  useEffect(() => {
    if (quizzData.configuration) {
      setQuizDetails(quizzData.configuration);
    }
  }, [quizzData]);

  const addConfig = () => {
    setLoading(true);

    quizzService
      .quizConfig(payload)
      .then(() => {
        setQuizDetails(initialValues);
        getAllQuizzes();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const editConfig = () => {
    setLoading(true);

    quizzService
      .updateQuizConfig(payload)
      .then(() => {
        getAllQuizzes();
        setQuizDetails(initialValues);
        setLoading(false);
      })

      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Card p="20px" align="center" direction="column" w="100%">
      <Box
        w="100%"
        mt={4}
        p={4}
        borderRadius={'12px'}
        border={'1px'}
        borderColor={'gray.350'}
        borderStyle={'solid'}
        style={{ position: 'relative' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            justifyContent: 'center',
          }}
        >
          <div>
            <div className="label">Word count</div>
            <Input
              placeholder="100"
              value={quizDetails.wordcount}
              onChange={(e) => handleChange('wordcount', e)}
              border={'1px'}
              borderColor={'gray.350'}
            />
          </div>
          <div>
            <div className="label">Max amount earning</div>
            <Input
              placeholder="$100"
              value={quizDetails.max_earn}
              onChange={(e) => handleChange('max_earn', e)}
              border={'1px'}
              borderColor={'gray.350'}
            />
          </div>{' '}
          <div>
            <div className="label">Max earn per correct answer</div>
            <Input
              placeholder="$100"
              value={quizDetails.earn_per_correct_answer}
              onChange={(e) => handleChange('earn_per_correct_answer', e)}
              border={'1px'}
              borderColor={'gray.350'}
            />
          </div>
        </div>
        <Flex mt={4} px="25px" justify="space-between" align="center">
          <div />
          <Button
            loadingText={'Saving...'}
            className={'app-main-btn'}
            w={'200px'}
            rightIcon={<AiOutlineSave />}
            onClick={() =>
              quizzData.configuration ? editConfig() : addConfig()
            }
          >
            Save
          </Button>
        </Flex>
      </Box>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {loading ? (
          <Loader />
        ) : (
          <CommonDataGrid data={quizzData.quizzes} columns={columnsDataQuiz} />
        )}
      </SimpleGrid>
    </Card>
  );
}
