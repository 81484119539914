import React, { useEffect, useState } from "react";
import moment from "moment";
import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    DateHeader,
    CustomHeader
} from "react-calendar-timeline/lib";
import { defaultHeaderFormats as headerFormats } from "react-calendar-timeline/lib/lib/default-config";
import "react-calendar-timeline/lib/Timeline.css";
import "./index.css";
import { PromotionsService } from 'services/promotions';
import Card from 'components/card/Card.js';
import EventDetailsModal from "./components/EventDetailsModal";
import { getStartofDate } from "utils/common";

var keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start",
    itemTimeEndKey: "end",
    groupLabelKey: "title"
};

const EVENT_COLOR = {
    active_promo: '#3ac2d1',
    past_promo: '#666',
    future_promo: '#0f0'
};

const Promotions = () => {
    const defaultTimeStart = new Date();
    const defaultTimeEnd = moment()
        .add(30, "day")
        .toDate();
    const defaultTimeRange = defaultTimeEnd - defaultTimeStart;

    const [loading, setLoading] = useState(false);
    const [promotions, setPromotions] = useState([]);
    const [visible_promos, setVisiblePromos] = useState([]);
    const [curEvent, setCurEvent] = useState(null);
    const [isEventDetailModal, showEventDetailModal] = useState(false);

    useEffect(() => {
        loadPromotions();
    }, [])

    useEffect(() => {
        onBoundsChange(defaultTimeStart, defaultTimeEnd)
    }, [promotions])

    const loadPromotions = async () => {
        try {
            setLoading(true);
            let { data } = await PromotionsService.getPromotions();
            let total = [];
            for (let i = 0; i < data?.promotions?.length; i++) {
                const item = data?.promotions[i];
                if (item.non_expired != 1) {
                    total.push({
                        id: item.id,
                        ...item,
                        data_title: item.title,
                        title: (
                            (moment(item.end_time, "YYYY-MM-DD  hh:mm:ss").diff(moment(item.start_time, "YYYY-MM-DD  hh:mm:ss"), 'days') < 2) ?
                                (item.is_discount == 1 ? 'D' : 'C') :
                                item.title
                        ),
                        group: 1,
                        allDay: item.non_expired == 1,
                        start: (
                            (moment(item.end_time, "YYYY-MM-DD  hh:mm:ss").diff(moment(item.start_time, "YYYY-MM-DD  hh:mm:ss"), 'days') < 2) ?
                                getStartofDate(item.start_time)
                                :
                                moment(item.start_time, "YYYY-MM-DD  hh:mm:ss")
                        ),
                        end: (item.non_expired == 1 ? null :
                            (
                                (moment(item.end_time, "YYYY-MM-DD  hh:mm:ss").diff(moment(item.start_time, "YYYY-MM-DD  hh:mm:ss"), 'days') < 2) ?
                                    getStartofDate(item.start_time).add(1, "day")
                                    :
                                    moment(item.end_time, "YYYY-MM-DD  hh:mm:ss")
                            )),
                        itemProps: {
                            style: {
                                background: (
                                    moment(item.start_time, "YYYY-MM-DD  hh:mm:ss").isAfter(moment(new Date())) ?
                                        EVENT_COLOR.future_promo
                                        :
                                        (item.non_expired == 1 || moment(item.end_time, "YYYY-MM-DD  hh:mm:ss").isAfter(moment(new Date()))) ?
                                            EVENT_COLOR.active_promo : EVENT_COLOR.past_promo
                                )
                            }
                        }
                    })
                }
            }
            setPromotions(total);
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    const onItemSelect = (id) => {
        const index = promotions.findIndex(p => p.id == id);
        if (index != -1) {
            setCurEvent(promotions[index]);
            showEventDetailModal(true);
        }
    }

    const onBoundsChange = (canvasTimeStart, canvasTimeEnd) => {
        const startTime = moment(canvasTimeStart);
        const endTime = moment(canvasTimeStart).add(30, "day");

        const tmp = promotions.filter(p => {
            if (startTime.isAfter(p.end) || endTime.isBefore(p.start)) {
                return false;
            }
            return true;
        });
        setVisiblePromos(tmp);
    }

    const itemRenderer = ({
        item,
        itemContext,
        getItemProps,
        getResizeProps
    }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
        return (
            <div {...getItemProps(item.itemProps)} className={'event-item'}>
                {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

                <div
                    className="rct-item-content"
                // style={{ maxHeight: `${itemContext.dimensions.height}` }}
                >
                    {itemContext.title}
                </div>

                {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
            </div>
        )
    }

    const groupRenderer = ({ group }) => {
        return (
            <ul className="custom-group">
                {visible_promos.map((p, index) => {
                    return (
                        <li>
                            {p.data_title}
                        </li>
                    );
                })}
            </ul>
        )
    }

    return (
        <Card p='20px' align='center' direction='column' w='100%' >
            <Timeline
                groups={[{ id: 1, }]}
                items={promotions}
                keys={keys}
                sidebarWidth={220}
                itemsSorted
                itemTouchSendsClick={false}
                stackItems
                lineHeight={56}
                itemHeightRatio={0.75}
                showCursorLine
                buffer={1}
                canMove={false}
                canResize={false}
                minZoom={defaultTimeRange}
                maxZoom={defaultTimeRange}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                itemRenderer={itemRenderer}
                groupRenderer={groupRenderer}
                className={'promot-calendar'}
                onItemSelect={(itemId, e) => {
                    onItemSelect(itemId)
                }}
                onBoundsChange={(start, end) => onBoundsChange(new Date(start), new Date(end))}
            >
                <TimelineHeaders className="sticky">
                    {/* <DateHeader unit="primaryHeader" /> */}
                    <SidebarHeader>
                        {({ getRootProps }) => {
                            return <div {...getRootProps()} className={'align-col-middle sidebar-header'}>Promotions</div>
                        }}
                    </SidebarHeader>
                    <CustomHeader>
                        {({
                            headerContext: { unit, intervals },
                            getRootProps,
                            getIntervalProps
                        }) => {
                            return (
                                <div {...getRootProps()}>
                                    {intervals.map((interval, index) => {
                                        if (new Date(interval.startTime).getDate() == 1 ||
                                            new Date(interval.startTime).getDate() == 15
                                        ) {
                                            return (
                                                <div
                                                    className="rct-dateHeader"
                                                    key={interval.startTime}
                                                    {...getIntervalProps({
                                                        interval
                                                    })}
                                                >
                                                    <span>{moment(new Date(interval.startTime)).format('MMMM YYYY')}</span>
                                                </div>
                                            );
                                        }

                                    })}
                                </div>
                            );
                        }}
                    </CustomHeader>
                    <DateHeader />
                </TimelineHeaders>
            </Timeline>
            {
                isEventDetailModal &&
                <EventDetailsModal
                    eventData={curEvent} showModal={isEventDetailModal} onClose={() => showEventDetailModal(false)}
                />
            }
        </Card>
    );
}

export default Promotions;