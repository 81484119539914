export const columnsDataFeedbacks = [
  {
    Header: 'No',
    accessor: 'id',
  },
  {
    Header: 'Customer',
    accessor: 'full_name',
  },
  {
    Header: 'Tag',
    accessor: 'tag',
  }, 
  {
    Header: 'Comment',
    accessor: 'comment',
  },
  {
    Header: 'Created Date',
    accessor: 'created_at',
  }, 
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  // },
];
