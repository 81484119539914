export const API_BASE_URL = 'https://prodapi.snapfood.al/api/';
// export const API_BASE_URL = 'http://localhost:8000/api';
// export const API_BASE_URL = 'https://stageapi.snapfood.al/api/';
// export const API_BASE_URL = 'http://10.10.11.118:8000/api/';
// export const API_BOOST_CORE_URL = 'http://localhost:81/api/v1/';
export const API_BOOST_CORE_URL = 'https://core.venueboost.io/api/v1/';
// export const API_BOOST_CORE_URL = 'http://localhost/api/v1/';
export const APP_KEY = 'SNAPFOOD-3exeAtM4CMRAKNWNdza92QyP4';
export const APP_BOOST_KEY = 'boost-sn-25012xC0R5-superadmin!';
export const PLATFORM = 'Superadmin';
export const DEFAULT_LANG = 'sq';

export const USER_PROFILE_IMG_BASE_URL = 'https://sf-users.s3.eu-central-1.amazonaws.com/';
export const ROLE_ADMIN = 'admin';
