import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Select,
  Link,
  Image,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
// Custom components
import Pagination from "react-js-pagination";
import { Fonts } from 'theme/theme';

export default function ColumnsTable(props) {
  const { columnsData, data } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const tableData = useMemo(() => data?.notifications || [], [data])
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize },

  } = tableInstance;

  const textColor = useColorModeValue('textColor.0', 'white');
  const thColor = useColorModeValue('textColor.3', 'white');
  const theadBgColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  console.log('pageIndex ', pageIndex)

  return (
    <>
      <Table {...getTableProps()} className={'custom-table'} variant="simple" mt="24px">
        <Thead backgroundColor={theadBgColor}>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  color={thColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}
              >
                {row.cells.map((cell, index) => {
                  let data = '';
                  if (cell.column.Header === 'NAME') {
                    data = (
                      <Text color={textColor} >
                        {cell.value}
                      </Text>
                    );
                  }
                  else if (cell.column.Header === 'Vendor') {
                    data = (
                      <Text color={textColor} >
                        {cell.row?.original?.vendor_data?.title}
                      </Text>
                    );
                  }
                  else {
                    data = (
                      <Text color={textColor} >
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex direction={{base: 'column', md: 'row'}} justify='space-between' mt="20px" align="center">
        <Flex className='custom-table-pagesize' justify={'center'} alignItems='center' me={'20px'}>
          <Text color={textColor} whiteSpace='nowrap' fontSize="16px" fontFamily={Fonts.semibold} >Show rows</Text>
          <Select
            value={pageSize}
            ms='16px'
            onChange={(e) => {
              setPageSize(e.target.value)
              scrollToTop()
            }}
          >
            <option value={10}>10 items</option>
            <option value={20}>20 items</option>
            <option value={30}>30 items</option>
            <option value={40}>40 items</option>
            <option value={50}>50 items</option>
          </Select>
        </Flex>
        <Pagination
          activePage={pageIndex + 1}
          itemsCountPerPage={pageSize}
          totalItemsCount={tableData.length}
          pageRangeDisplayed={5}
          onChange={(newPage) => {
            gotoPage(newPage - 1);
            scrollToTop();
          }}
          innerClass={'custom-table-pagination pagination'}
          itemClassFirst={'first-btn'}
          itemClassLast={'last-btn'}
          itemClass={'page-btn'}
        />
      </Flex>
    </>
  );
}
