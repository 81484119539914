import { ApiFactory } from '../index';

const PREFIX = '/superadmin/notifications';

const API_LIST = {
  FETCH_ALL: `${PREFIX}`,
};

export const NotificationService = {
  getAllNotifications(searQuery = '') {
    return ApiFactory.get(`${API_LIST.FETCH_ALL}${searQuery}`);
  },
  createNotification(data) {
    return ApiFactory.post(API_LIST.FETCH_ALL, data);
  },
};
