import Cookies from 'universal-cookie';
import { Config } from '../constants';
import { createUUID } from '../utils/common';

const cookies = new Cookies();

export const KEYS = {
  LANGUAGE: 'snapfood-admin-language',
  DEVICE_UNIQUE_KEY: 'snapfood-admin-device-unique-id',
  APP_TOKEN: 'snapfood-admin-app-token',
};

export const setAppToken = (token) => {
  const d = new Date();
  d.setTime(d.getTime() + 6 * 30 * 24 * 60 * 60 * 1000); // 6 months expiration
  cookies.set(KEYS.APP_TOKEN, token, { path: '/', expires: d });
};

export const clearAppToken = () => {
  const d = new Date('1970-01-01');
  cookies.remove(KEYS.APP_TOKEN, { path: '/', expires: d });
};

export const getAppToken = () => cookies.get(KEYS.APP_TOKEN);

export const getDeviceUniqueId = () => {
  let uniqueId = localStorage.getItem(KEYS.DEVICE_UNIQUE_KEY);
  if (uniqueId == null) {
    uniqueId = createUUID();
    localStorage.setItem(KEYS.DEVICE_UNIQUE_KEY, uniqueId);
  }
  return uniqueId;
};

export const getLanguage = () => {
  let savedLang = localStorage.getItem(KEYS.LANGUAGE);
  if (savedLang == null || (savedLang !== 'en' && savedLang !== 'sq')) {
    savedLang = Config.DEFAULT_LANG;
    localStorage.setItem(KEYS.LANGUAGE, savedLang);
  }
  return savedLang;
};

export const setLanguage = (lang) => {
  localStorage.setItem(KEYS.LANGUAGE, lang);
};

export const getStorageKey = (key) => {
  const data = localStorage.getItem(key);
  if (data != null) {
    return JSON.parse(data);
  }
  return null;
};

export const setStorageKey = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeStorageKey = (key) => {
  localStorage.removeItem(key);
};
