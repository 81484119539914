import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import Timeline, {
    TimelineHeaders,
    SidebarHeader,
    DateHeader,
    CustomHeader
} from "react-calendar-timeline/lib";
import "react-calendar-timeline/lib/Timeline.css";
import "../index.css";
import Card from 'components/card/Card.js';
import EventDetailsModal from '../details/EventDetailsModal';
import { getStartofDate } from "utils/common";

var keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start",
    itemTimeEndKey: "end",
    groupLabelKey: "title"
};

const EVENT_COLOR = {
    active_promo: '#3ac2d1',
    past_promo: '#666',
    future_promo: '#0f0'
};

const HCalendar = ({ data }) => {
    const defaultTimeStart = new Date();
    const defaultTimeEnd = moment()
        .add(14, "day")
        .toDate();
    const defaultTimeRange = defaultTimeEnd - defaultTimeStart;

    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [visible_promos, setVisiblePromos] = useState([]);
    const [curEvent, setCurEvent] = useState(null);
    const [isEventDetailModal, showEventDetailModal] = useState(false);


    useEffect(() => {
        onBoundsChange(defaultTimeStart, defaultTimeEnd)
    }, [notifications])

    useEffect(() => {
        loadNotifications(data);
    }, [data])

    const getTitle = (noti) => {
        if (noti.type == 'general_notification') {
            return 'General'
        }
        else if (noti.type == '30_no_order_notification') {
            return '30days No Order'
        }
        else if (noti.type == 'vendor_notification') {
            return 'Vendor'
        }
        else if (noti.type == 'general_referral_notification') {
            return 'Referral'
        }
        else if (noti.type == 'general_earn_notification') {
            return 'Earn'
        }
        else if (noti.type == 'general_cashback_notification') {
            return 'Cashback'
        }
        return 'General'
    }

    const loadNotifications = useCallback(async (data) => {
        try {
            let total = [];
            for (let i = 0; i < data?.notifications?.length; i++) {
                const item = data?.notifications[i];
                if ((item.is_sent == 1 && item.created_at != null) || item.schedule_time != null) {
                    total.push({
                        id: item.id,
                        ...item,
                        data_title: item.title,
                        title: getTitle(item),
                        group: 1,
                        start: getStartofDate((item.is_sent == 1 ? item.created_at : item.schedule_time), '01:30:00'),
                        end: getStartofDate((item.is_sent == 1 ? item.created_at : item.schedule_time)).add(1, "day").subtract(90, 'minute'),
                        itemProps: {
                            style: {
                                background: (item.is_sent != 1 ? EVENT_COLOR.active_promo : EVENT_COLOR.past_promo)
                            }
                        }
                    })
                }
            }
            setNotifications(total);
        } catch (error) {
            console.log(error)
        }
    }, [])

    const onItemSelect = (id) => {
        const index = notifications.findIndex(p => p.id == id);
        if (index != -1) {
            setCurEvent(notifications[index]);
            showEventDetailModal(true);
        }
    }

    const onBoundsChange = (canvasTimeStart, canvasTimeEnd) => {
        const startTime = moment(canvasTimeStart);
        const endTime = moment(canvasTimeStart).add(14, "day");

        const tmp = notifications.filter(p => {
            if (startTime.isAfter(p.end) || endTime.isBefore(p.start)) {
                return false;
            }
            return true;
        });
        setVisiblePromos(tmp);
    }

    console.log('visible prmo', visible_promos)

    const itemRenderer = ({
        item,
        itemContext,
        getItemProps,
        getResizeProps
    }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
        return (
            <div {...getItemProps(item.itemProps)} className={'event-item'}>
                {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

                <div
                    className="rct-item-content"
                // style={{ maxHeight: `${itemContext.dimensions.height}` }}
                >
                    {itemContext.title}
                </div>

                {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
            </div>
        )
    }

    const groupRenderer = ({ group }) => {
        return (
            <ul className="custom-group">
                {visible_promos.map((p, index) => {
                    return (
                        <li>
                            {p.data_title}
                        </li>
                    );
                })}
            </ul>
        )
    }

    return (
        <Card p='20px' align='center' direction='column' w='100%' >
            <Timeline
                groups={[{ id: 1, }]}
                items={notifications}
                keys={keys}
                sidebarWidth={220}
                itemsSorted
                itemTouchSendsClick={false}
                stackItems
                lineHeight={56}
                itemHeightRatio={0.75}
                showCursorLine
                buffer={1}
                canMove={false}
                canResize={false}
                minZoom={defaultTimeRange}
                maxZoom={defaultTimeRange}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                itemRenderer={itemRenderer}
                groupRenderer={groupRenderer}
                className={'promot-calendar'}
                onItemSelect={(itemId, e) => {
                    onItemSelect(itemId)
                }}
                onBoundsChange={(start, end) => onBoundsChange(new Date(start), new Date(end))}
            >
                <TimelineHeaders className="sticky">
                    {/* <DateHeader unit="primaryHeader" /> */}
                    <SidebarHeader>
                        {({ getRootProps }) => {
                            return <div {...getRootProps()} className={'align-col-middle sidebar-header'}>Notifications</div>
                        }}
                    </SidebarHeader>
                    <CustomHeader>
                        {({
                            headerContext: { unit, intervals },
                            getRootProps,
                            getIntervalProps
                        }) => {
                            return (
                                <div {...getRootProps()}>
                                    {intervals.map((interval, index) => {
                                        if (new Date(interval.startTime).getDate() == 1 ||
                                            new Date(interval.startTime).getDate() == 15
                                        ) {
                                            return (
                                                <div
                                                    className="rct-dateHeader"
                                                    key={interval.startTime}
                                                    {...getIntervalProps({
                                                        interval
                                                    })}
                                                >
                                                    <span>{moment(new Date(interval.startTime)).format('MMMM YYYY')}</span>
                                                </div>
                                            );
                                        }

                                    })}
                                </div>
                            );
                        }}
                    </CustomHeader>
                    <DateHeader />
                </TimelineHeaders>
            </Timeline>
            {
                isEventDetailModal &&
                <EventDetailsModal
                    eventData={curEvent} showModal={isEventDetailModal} onClose={() => showEventDetailModal(false)}
                />
            }
        </Card>
    );
}

export default HCalendar;