export const columnsData = [
  {
    Header: 'No',
    accessor: 'id',
  },
  {
    Header: 'Full name',
    accessor: 'full_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  }, 
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Provider',
    accessor: 'provider',
  },
  {
    Header: 'Source',
    accessor: 'source',
  },
  {
    Header: 'Interests',
    accessor: 'interests',
  },
  {
    Header: 'Created Date',
    accessor: 'created_at',
  }, 
  {
    Header: 'Action',
    accessor: 'action',
  },
];
