import { ApiFactory } from '../index';

const PREFIX = '/statistics';

const API_LIST = {
  TOP_VENDORS: `${PREFIX}/orders/topvendors`,
  TOP_CUSTOMERS: `${PREFIX}/orders/topcustomers`,
  GET_BY_SOURCE: `${PREFIX}/orders/get-by-source`,
  GET_BY_HOURS: `${PREFIX}/orders/get-by-hours`,
  GET_REVENUE: `${PREFIX}/orders/get-revenue`,
};

export const OrderStatisticsService = {
  getTopVendors(searchQuery = '') {
    return ApiFactory.get(`${API_LIST.TOP_VENDORS}${searchQuery || ''}`);
  },
  getTopCustomers(searchQuery = '') {
    return ApiFactory.get(`${API_LIST.TOP_CUSTOMERS}${searchQuery || ''}`);
  },
  getNumOrdersBySource(searchQuery = '') {
    return ApiFactory.get(`${API_LIST.GET_BY_SOURCE}${searchQuery || ''}`);
  },
  getNumOrdersByHours(searchQuery = '') {
    return ApiFactory.get(`${API_LIST.GET_BY_HOURS}${searchQuery || ''}`);
  },
  getRevenueData(searchQuery = '') {
    return ApiFactory.get(`${API_LIST.GET_REVENUE}${searchQuery || ''}`);
  },
};
