import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl, FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { connect } from 'react-redux';

import { Field, Form, Formik } from 'formik';

// Custom components
import DefaultAuth from 'layouts/auth/Default';
// Assets
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { login } from '../../../store/actions/auth';
import { Fonts } from 'theme/theme';
import AuthInput from 'components/fields/AuthInput';
import Svg_email from 'assets/img/auth/mail.svg'
import Svg_lock from 'assets/img/auth/lock.svg'
import Snapfoodlogo from 'assets/img/logo.png';

function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue('textColor.0', 'white');
  const subtitleColor = 'textColor.1';
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const dispatch = useDispatch();
  const history = useHistory();

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const doLogin = async (values, actions) => {
    console.log('do login ', values)
    const user = await dispatch(login(values));
    console.log(user);
    actions.setSubmitting(false);
    history.push('/admin/analytics');
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        width={{ base: '100%', md: '50%' }}
        h='100vh'
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
      >
        <Flex
          zIndex='2'
          direction='column'
          justify={'center'}
          alignItems='flex-start'
          w={{ base: '100%', md: '404px' }}
          maxW='calc(100% - 30px)'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          mb={{ base: '20px', }}
          position='relative'
        >
          <Flex mb={'40px'} >
            <Image src={Snapfoodlogo} height="34px" me={'11px'} borderRadius={'6px'} />
            <Text color={titleColor} fontSize={27} lineHeight={'125%'} fontFamily={Fonts.bold}>Superadmin</Text>
          </Flex>
          <Text fontSize={'20px'} lineHeight='125%' fontFamily={Fonts.semibold} color={titleColor} mb='12px'>Login into your Superadmin account</Text>
          <Text
            mb='32px'
            color={subtitleColor}
            fontFamily={Fonts.regular}
            fontSize='16px'
            lineHeight={'160%'}
          >
            Welcome back! Please enter you account informatoon
          </Text>
          <Formik
            initialValues={{ email: '', password: '' }}
            validate={validate}
            onSubmit={doLogin}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Field name="email" >
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.email && touched.email} style={{ marginBottom: '16px' }}>
                      <AuthInput
                        isRequired={true}
                        type="email"
                        placeholder='Email'
                        icon={<Image src={Svg_email} />}
                        field={field}
                      />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field, form }) => (
                    <FormControl isInvalid={errors.password && touched.password} style={{ marginBottom: '16px' }}>
                      <AuthInput
                        isRequired={true}
                        type="password"
                        placeholder='Password'
                        icon={<Image src={Svg_lock} />}
                        field={field}
                      />
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  fontSize='16px'
                  lineHeight={'140%'}
                  fontFamily={Fonts.semibold}
                  w='100%'
                  h='56px'
                  mt='16px'
                  borderRadius={'12px'}
                  color={'white'}
                  backgroundColor={'blue.2'}
                  isLoading={isSubmitting}
                  _hover={{ backgroundColor: 'blue.1' }}
                  type='submit'>
                  Log in
                </Button>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default connect()(SignIn);
