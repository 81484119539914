import { Fragment } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Storage } from '../../services';
import Dashboard from '../admin';
import Auth from '../auth';
import AdminLayout from '../admin';
import AuthLayout from '../auth';
import RTLLayout from '../rtl';


export const IndexLayout = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  const Layouts = {
    auth: AuthLayout,
    dashboard: AdminLayout,
    rtl: RTLLayout
  };

  // Layout Rendering
  const getLayout = () => {
    if (/admin/i.test(pathname)) {
      return 'dashboard';
    }
    else if (/auth/i.test(pathname)) {
      return 'auth';
    }
    else if (/rtl/i.test(pathname)) {
      return 'rtl';
    }
  };

  const Container = Layouts[getLayout()];
  const token = Storage.getAppToken();
  const isLoginLayout = /auth/i.test(pathname);

  console.log('====================== ', token, isLoginLayout, getLayout(), pathname)
  const BootstrappedLayout = () => {
    // redirect to login page if current is not login page and user not authorized
    if (!isLoginLayout && !token) {
      return <Redirect to="/auth/sign-in/default" />;
    }
    // redirect to main dashboard when user on login page and authorized
    if (isLoginLayout && token) {
      return <Redirect to="/admin/analytics" />;
    }
    if (pathname == '/') {
      return <Redirect to="/auth/sign-in/default" />;
    }
    // in other case render previously set layout
    return <Container>{children}</Container>;
  };

  return (
    <Fragment>
      {BootstrappedLayout()}
    </Fragment>
  );
};
