import { ApiFactory } from '../index';

const PREFIX = '/superadmin/vendor';

const API_LIST = {
  FETCH_SEARCH: `${PREFIX}/search`,
};

export const VendorService = {
  getSearch(searQuery = '') {
    return ApiFactory.get(`${API_LIST.FETCH_SEARCH}${searQuery}`);
  },
};
