import { Flex } from '@chakra-ui/react';
import { AsyncSelect, chakraComponents } from "chakra-react-select";
import React from 'react';
import { VendorService } from 'services/vendor';


const VendorSelect = ({ value, isDisabled = false, onChange }) => {

  // These are the defaults for each of the custom props
  const asyncComponents = {
    LoadingIndicator: (props) => (
      <chakraComponents.LoadingIndicator
        // The color of the main line which makes up the spinner
        // This could be accomplished using `chakraStyles` but it is also available as a custom prop
        color="currentColor" // <-- This default's to your theme's text color (Light mode: gray.700 | Dark mode: whiteAlpha.900)
        // The color of the remaining space that makes up the spinner
        emptyColor="transparent"
        // The `size` prop on the Chakra spinner
        // Defaults to one size smaller than the Select's size
        spinnerSize="md"
        // A CSS <time> variable (s or ms) which determines the time it takes for the spinner to make one full rotation
        speed="0.45s"
        // A CSS size string representing the thickness of the spinner's line
        thickness="2px"
        // Don't forget to forward the props!
        {...props}
      />
    ),
  };

  const searchVendor = (inputValue, callback) => {
    VendorService.getSearch(`?name=${inputValue}&limit=15`)
      .then(({ data }) => {
        const tmp = (data?.vendors || []).map(item => ({
          value: item.id,
          label: item.title
        }));
        callback(tmp);
        console.log('searchVendor ', tmp)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <AsyncSelect
      isSearchable
      isDisabled={isDisabled}
      className='vendor-select'
      placeholder="Select vendor..."
      components={asyncComponents}
      focusBorderColor={'transparent'}
      classNamePrefix={'vendor-select'}
      classNames= {{}}
      loadOptions={searchVendor}
      value={value}
      onChange={onChange}
    />
  )
};

export default VendorSelect;
