import { ApiFactory } from '../index';

const PREFIX = '/statistics';

const API_LIST = {
  DISCOUNTS: `${PREFIX}/discounts/totalUsed`,
  COUPONS_TOTAL_USED: `${PREFIX}/discounts/totalUsed`,
  COUPONS_TOTAL_ORDERS: `${PREFIX}/coupons/totalOrders`,
  COUPONS_TOTAL_AMOUNT: `${PREFIX}/coupons/totalAmount`,
  DISCOUNTS_TOTAL_AMOUNT: `${PREFIX}/discounts/totalAmount`,
  DISCOUNTS_TOTAL_ORDERS: `${PREFIX}/discounts/totalOrders`,
  PROMOTIONS_TOTAL_AMOUNT: `${PREFIX}/promotions/totalAmount`,
  PROMOTIONS_TOTAL_ORDERS: `${PREFIX}/promotions/totalOrders`,
  PROMOTIONS_LIST_ACTIVE: `${PREFIX}/promotions`,
  CASHBACK_TOTAL_AMOUNT_EARNED: `${PREFIX}/cashback/totalAmountEarned`,
  CASHBACK_TOTAL_AMOUNT_USED: `${PREFIX}/cashback/totalAmountUsed`,
  CASHBACK_MOST_USED_VALUES: `${PREFIX}/cashback/mostUsedValues`,
};

export const FoodStatisticsService = {
  getDiscounts(searQuery) {
    return ApiFactory.get(`${API_LIST.DISCOUNTS}${searQuery}`);
  },
  getCoupons(searchQuery) {
    return ApiFactory.get(`${API_LIST.COUPONS_TOTAL_USED}${searchQuery}`);
  },
  getTotalOrdersCoupons(searchQuery) {
    return ApiFactory.get(`${API_LIST.COUPONS_TOTAL_ORDERS}${searchQuery || ''}`);
  },
  getTotalAmountCoupons(searchQuery) {
    return ApiFactory.get(`${API_LIST.COUPONS_TOTAL_AMOUNT}${searchQuery || ''}`);
  },
  getTotalOrdersDiscounts(searchQuery) {
    return ApiFactory.get(`${API_LIST.DISCOUNTS_TOTAL_ORDERS}${searchQuery}`);
  },
  getTotalAmountDiscounts(searchQuery) {
    return ApiFactory.get(`${API_LIST.DISCOUNTS_TOTAL_AMOUNT}${searchQuery}`);
  },
  getTotalOrdersPromotions(searchQuery) {
    return ApiFactory.get(`${API_LIST.PROMOTIONS_TOTAL_ORDERS}${searchQuery}`);
  },
  getTotalAmountPromotions(searchQuery) {
    return ApiFactory.get(`${API_LIST.PROMOTIONS_TOTAL_AMOUNT}${searchQuery}`);
  },
  getActivePromotions(searchQuery) {
    return ApiFactory.get(`${API_LIST.PROMOTIONS_LIST_ACTIVE}${searchQuery}`);
  },
  getTotalAmountEarnedCashback(searchQuery) {
    return ApiFactory.get(`${API_LIST.CASHBACK_TOTAL_AMOUNT_EARNED}${searchQuery}`);
  },
  getTotalAmountUsedCashback(searchQuery) {
    return ApiFactory.get(`${API_LIST.CASHBACK_TOTAL_AMOUNT_USED}${searchQuery}`);
  },
  getMostUsedValuesCashback(searchQuery) {
    return ApiFactory.get(`${API_LIST.CASHBACK_MOST_USED_VALUES}${searchQuery}`);
  },
};
