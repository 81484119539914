import { ApiFactory } from '../index';

const PREFIX = '/superadmin/customers';

const API_LIST = {
  FETCH_ALL: `${PREFIX}`,
};

export const CustomerService = {
  get(searQuery = '') {
    return ApiFactory.get(`${API_LIST.FETCH_ALL}${searQuery}`);
  },
};
