import { ApiFactory } from './index';

const API_LIST = {
  getLoggedInUserData: 'users',
  emailLogin: 'login',
};

const AuthService = {
  getLoggedInUserData() {
    return ApiFactory.get(API_LIST.getLoggedInUserData);
  },
  emailLogin(params) {
    return ApiFactory.post(API_LIST.emailLogin, params);
  },
};

export default AuthService;
