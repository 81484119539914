import { useQuery } from 'react-query';
import { FoodStatisticsService } from 'services/statistics';
import { CardChart } from 'components/statistics/components/CardChart';
import { useSaveQueryParams } from 'hooks/use-save-query-params';
import Card from 'components/card/Card';
import { CirclesWithBar } from 'react-loader-spinner';
import { Fonts } from 'theme/theme';

export const MostUsedValues = () => {
  const { queryParams, onSave } = useSaveQueryParams();

  const { status, data, error, isError, isLoading } = useQuery(
    ['getMostUsedValuesCashback', queryParams],
    () => FoodStatisticsService.getMostUsedValuesCashback(queryParams)
  );

  if (isLoading) {
    return <Card align="center" w="100%" h="20rem" justifyContent="center">
      <CirclesWithBar
        height="120"
        width="120"
        color="#22adc4"
        ariaLabel="bars-loading"
        wrapperStyle={{ margin: 'auto' }}
        wrapperClass=""
        visible={true}
      />
    </Card>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const chartData = Object.values(data.data);
 
  const options = {
    chart: {
      width: '100%',
      height: '100%',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
    },
    colors: ['#2563EB','#38BDF8', '#DBEAFE', `#${Math.floor(Math.random()*16777215).toString(16)}`],
    labels: [
      '< 50 Leke',
      '> 50 < 100 Leke',
      '> 100 < 200 Leke',
      '> 200 Leke'
    ],
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    legend: {
      fontSize: '14px',
      position: 'bottom',
      fontFamily: Fonts.semibold,
      labels: {
        colors: '#64748B',
      },
      itemMargin: {
        horizontal: 14,
        vertical: 5
      },
    }
  };

  return <CardChart
    title="Most Used Values"
    chartData={chartData}
    chartType={'donut'}
    chartOptions={options}
    isLoading={isLoading}
    onSave={onSave}
  />;
};
