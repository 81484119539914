import { ApiFactory } from '../index';

const PREFIX = '/statistics';

const API_LIST = {
  CREDITS: `${PREFIX}/wallet/credits`,
  CUSTOMERS: `${PREFIX}/wallet/customers`,
};

export const WalletStatisticsService = {
  getCredits(searchQuery = '') {
    return ApiFactory.get(`${API_LIST.CREDITS}${searchQuery || ''}`);
  },
  getCustomers(searchQuery = '') {
    return ApiFactory.get(`${API_LIST.CUSTOMERS}${searchQuery || ''}`);
  },
};
