import { useQuery } from 'react-query';
import { OrderStatisticsService } from 'services/statistics';
import { CardChart } from 'components/statistics/components/CardChart';
import { useSaveQueryParams } from 'hooks/use-save-query-params';
import Card from 'components/card/Card';
import { CirclesWithBar } from 'react-loader-spinner';
import { Fonts } from 'theme/theme';

export const OrdersBySource = (props) => {
  const { queryParams, onSave } = useSaveQueryParams();

  const { status, data, error, isError, isLoading } = useQuery(
    ['getNumOrdersBySource', queryParams],
    () => OrderStatisticsService.getNumOrdersBySource(queryParams)
  );

  if (isLoading) {
    return <Card align="center" w="100%" h="20rem" justify="center">
      <CirclesWithBar
        height="80"
        width="80"
        color="#22adc4"
        ariaLabel="bars-loading"
        wrapperStyle={{ margin: 'auto' }}
        wrapperClass=""
        visible={true}
      />
    </Card>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  console.log('============data?.data ', data?.data)
  const chartData = data?.data?.data?.length == 0 ? [0, 0, 0] : data?.data?.data
  const chartLabels = data?.data?.labels.length == 0 ? ['Android', 'iOS', 'Web'] : data?.data?.labels

  const options = {
    chart: {
      type: 'donut',
      toolbar: {
        show: false,
      },
    },
    colors: ['#DBEAFE', '#2563EB','#38BDF8'],
    labels: chartLabels,
    legend: {
      horizontalAlign: 'left',
      verticalAlign: 'left'
    },
    plotOptions: {
      pie: {
        expandOnClick: true,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10
        },
        donut: {
          size: '65%',
          background: 'white',
          // labels: {
          //   show: false, 
          //   value: {
          //     show: true,
          //     fontSize: '16px',
          //     fontFamily: 'Helvetica, Arial, sans-serif',
          //     fontWeight: 400,
          //     color: undefined,
          //     offsetY: 16,
          //     formatter: function (val) {
          //       return val
          //     }
          //   },

          // }
        },
      }
    },
    legend: {
      fontSize: '14px',
      position: 'bottom',
      fontFamily: Fonts.semibold,
      labels: {
        colors: '#64748B',
      },
      itemMargin: {
        horizontal: 14,
        vertical: 5
      },
    }
  };

  return <CardChart
    title="Orders by Source"
    chartData={chartData}
    chartOptions={options}
    chartType={'donut'}
    width={380}
    isLoading={isLoading}
    onSave={onSave}
  />;
};
