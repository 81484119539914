import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import ListDatatable from "./components/ListDatatable";
import {
  columnsData,
} from "./variables/columnsData";


export default function Customers() {
  return (
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
      <ListDatatable
        columnsData={columnsData}
      />
    </SimpleGrid>
  );
}
