export const columnsData = [
  {
    Header: 'No',
    accessor: 'id',
  },
  {
    Header: 'Order Number',
    accessor: 'order_number',
  },
  {
    Header: 'Ordered Date',
    accessor: 'created_at',
  },
  {
    Header: 'Total Price',
    accessor: 'total_price',
  },
  {
    Header: 'Total Tax',
    accessor: 'tax_total',
  },
  {
    Header: 'Vendor',
    accessor: 'vendor_title',
  },
  {
    Header: 'Customer',
    accessor: 'customer_name',
  },
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  // },
];
