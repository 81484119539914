import {
    Box,
    Button, ButtonGroup,
    Flex,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Text,
    Image,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { MdBarChart, MdCalendarToday } from 'react-icons/md';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays, subDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { ReactComponent as Svg_calendar } from 'assets/img/icons/calendar_black.svg';
import { ReactComponent as Svg_down } from 'assets/img/icons/chevron-down.svg';
import moment from 'moment';

const CustomDateRangePicker = ({ title, onSave = () => { } }) => {
    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue(
        { bg: 'secondaryGray.400' },
        { bg: 'whiteAlpha.50' },
    );
    const bgFocus = useColorModeValue(
        { bg: 'secondaryGray.300' },
        { bg: 'whiteAlpha.100' },
    );
    const { onClose, onOpen, isOpen } = useDisclosure();

    const [state, setState] = useState([
        {
            startDate: subDays(new Date(), 7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleSave = (dateState) => {
        const { startDate, endDate } = dateState[0];
        return {
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
        };
    };

    const handleClear = () => {
        setState([
            {
                startDate: subDays(new Date(), 7),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
    };

    console.log('state ', state)
    return (
        <Popover
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            focus-visible="false"
            class="custom-date-range-picker"
        >
            <PopoverTrigger>
                <Button
                    align="center"
                    justifyContent="center"
                    bg={bgButton}
                    lineHeight="100%"
                    borderRadius="10px"
                    px={'16px'}
                    py={'12px'}
                    borderRadius={'12px'}
                    backgroundColor={'#fff'}
                >
                    <Svg_calendar />
                    <span style={{ paddingLeft: 8, paddingRight: 8 }}>{moment(state[0].startDate).format('MMM DD')} - {moment(state[0].endDate).format('MMM DD')}</span>
                    <Svg_down />
                </Button>
            </PopoverTrigger>
            <PopoverContent w="100%" h="100%">
                <PopoverArrow />
                <PopoverHeader>{title}</PopoverHeader>
                <PopoverCloseButton />
                <PopoverBody>
                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                    />
                </PopoverBody>
                <PopoverFooter display="flex" justifyContent="flex-end">
                    <ButtonGroup size="sm">
                        <Button variant="outline" onClick={handleClear}>Clear</Button>
                        <Button colorScheme="brand" onClick={() => {
                            onSave(handleSave(state));
                            onClose();
                        }}>Done</Button>
                    </ButtonGroup>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    );
};


export default CustomDateRangePicker;