export const columnsData = [
  {
    Header: 'No',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Venue',
    accessor: 'restaurant_name',
  },
  {
    Header: 'Country',
    accessor: 'restaurant_country',
  },
  {
    Header: 'Demo',
    accessor: 'is_demo',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Received',
    accessor: 'created_at',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
