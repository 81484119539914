import { ApiFactory } from '../index';

const PREFIX = '/superadmin/settings';

const API_LIST = {
  FETCH_ALL: `${PREFIX}`,
};

export const SettingsService = {
  get(searQuery = '') {
    return ApiFactory.get(`${API_LIST.FETCH_ALL}${searQuery}`);
  },
  update(data) {
    return ApiFactory.put(API_LIST.FETCH_ALL, data);
  },
};
