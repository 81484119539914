import { ApiFactory } from '../index';

const PREFIX = '/report';

const API_LIST = {
  FETCH_ALL: `${PREFIX}/get-all`,
};

export const ReportsService = {
  getAllReports(searQuery='') {
    return ApiFactory.get(`${API_LIST.FETCH_ALL}${searQuery}`);
  },
};
