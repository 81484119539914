import {
  Avatar, Box, Button, Flex, Text, useColorModeValue, Image
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import React, { useMemo } from 'react';
import { Fonts } from 'theme/theme';
import { isEmpty } from 'utils/common';

export default function InterestsInfo(props) {
  const { interests } = props;
  // Chakra Color Mode
  const tagBgColor = useColorModeValue('gray.400', 'whiteAlpha.100');
  const tagTxtColor = useColorModeValue('brand.300', 'white');
  const categoryTxtColor = useColorModeValue('textColor.0', 'white');

  const foods = useMemo(() => {
    return (interests || []).filter(item => item.category == 'food');
  }, [interests]);

  const socials = useMemo(() => {
    return (interests || []).filter(item => item.category == 'social');
  }, [interests]);

  return (
    <Flex w={'100%'} direction={'column'} alignItems={'flex-start'}>
      <Text fontSize={'16px'} lineHeight={'24px'} fontFamily={Fonts.bold} color={categoryTxtColor}>Food Interests</Text>
      <Flex
        mt={{ base: '12px', md: '18px', lg: '24px' }}
        align="center"
        width={'100%'}
        borderRadius={12}
        flexWrap={'wrap'}
      >
        {
          foods.map(item =>
            <Box
              key={item.id}
              fontSize={'16px'}
              lineHeight={'25px'}
              fontFamily={Fonts.medium}
              color={tagTxtColor}
              borderRadius={'40px'}
              bgColor={tagBgColor}
              px={'16px'}
              py={'7px'}
              mr={'12px'}
            >
              {item.title}
            </Box>
          )
        }
      </Flex>
      <Text fontSize={'16px'} mt={'24px'} lineHeight={'24px'} fontFamily={Fonts.bold} color={categoryTxtColor}>Social Interests</Text>
      <Flex
        mt={{ base: '12px', md: '18px', lg: '24px' }}
        align="center"
        width={'100%'}
        borderRadius={12}
        flexWrap={'wrap'}
      >
        {
          socials.map(item =>
            <Box
              key={item.id}
              fontSize={'16px'}
              lineHeight={'25px'}
              fontFamily={Fonts.medium}
              color={tagTxtColor}
              borderRadius={'40px'}
              bgColor={tagBgColor}
              px={'16px'}
              py={'7px'}
              mr={'12px'}
            >
              {item.title}
            </Box>
          )
        }
      </Flex>
    </Flex>
  );
}
