import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, Flex, Button, FormControl, FormLabel, Stack, Image, Switch, useToast } from "@chakra-ui/react";
import {
  AiOutlinePlus
} from 'react-icons/ai';
import { useQuery } from "react-query";
import Card from 'components/card/Card';
import ListDatatable from "./components/ListDatatable";
import {
  columnsDataFeedbacks,
} from "./variables/columnsData";
import { InterestsService } from 'services/interests'
import { SettingsService } from 'services/settings'
import CreateNotiModal from "./details/CreateModal";

export default function Interests() {
  const toast = useToast();
  const { status, data, error, isError, isLoading, refetch } = useQuery(
    ['notifications-fetch-all'],
    () => InterestsService.getAllInterests()
  );
  const [isCreateModal, showCreateModal] = useState(false);
  const [isEnabled, setEnabled] = useState(false);
  const [curItem, setCurItem] = useState(null);

  useEffect(() => {
    loadSetting();
  }, [])

  const loadSetting = () => {
    SettingsService.get()
      .then(({ data }) => {
        console.log(data?.settings?.enable_interests)
        setEnabled(data?.settings?.enable_interests == 1);
      })
      .catch((err) => {
      })
  }

  const onEdit = (item) => {
    setCurItem(item);
    showCreateModal(true);
  }

  const onDelete = (item) => {
    InterestsService.deleteInterest({ id: item?.id })
      .then(({ data }) => {
        refetch();
      })
      .catch((err) => {
        toast({
          title: `Something went wrong!`,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        })
      })
  }

  const onChangeEnable = (e) => {
    SettingsService.update({ settings: { enable_interests: (e?.target?.checked ? 1 : 0) } })
      .then(({ data }) => {
        setEnabled(!e?.target?.checked)
      })
      .catch((err) => {
      })
  }

  return (
    <>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <Card
          direction="column"
          w="100%"
          px="24px"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <FormControl display='flex' alignItems='center'>
            <FormLabel htmlFor='enable-interest' mb='0'>
              Enable interest?
            </FormLabel>
            <Switch id='enable-interest' isChecked={isEnabled} onChange={onChangeEnable} />
          </FormControl>
          <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
            <Button className="app-main-btn" mt={'20px'} rightIcon={<AiOutlinePlus />} onClick={() => showCreateModal(true)}>
              New Interest
            </Button>
          </Flex>
          <ListDatatable
            data={data?.data}
            columnsData={columnsDataFeedbacks}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Card>
      </SimpleGrid>
      <CreateNotiModal
        showModal={isCreateModal}
        item={curItem}
        onClose={() => showCreateModal(false)}
        onReload={() => {
          showCreateModal(false)
          refetch()
        }}
      />
    </>
  );
}
