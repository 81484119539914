import { Fragment } from 'react';

// Generic component - a nicely animated loading spinner
export default function Loader() {
  return (
    <>
      <div className="overlay" />
      <div className="loader">
        <div className="lds-roller">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </>
  );
}
