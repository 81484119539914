import { ApiFactory } from '../index';

const PREFIX = '/feedback';

const API_LIST = {
  FETCH_ALL: `${PREFIX}/get-all`,
};

export const FeedbacksService = {
  getAllFeedbacks(searQuery = '') {
    return ApiFactory.get(`${API_LIST.FETCH_ALL}${searQuery}`);
  },
};
