import moment from 'moment';
import Hashids from 'hashids';
import { Config } from '../constants';

// eslint-disable-next-line no-unused-vars
export const PrintLog = (object) => {
  console.log(object);
};

export const decodeHash = (hash_string) => {
  const hashids = new Hashids('sss', 6);
  const ids = hashids.decode(hash_string);
  if (ids && ids.length > 0) {
    return ids[0];
  }
  return null;
};

export const createUUID = () => {
  const s = [];
  const hexDigits = '0123456789ABCDEF';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  const uuid = s.join('');
  return uuid;
};

export const validateEmailAddress = (email) => {
  const reg = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

export const validateUserData = ({
  full_name, email, phone, password, pass2,
}, isNew) => new Promise((resolve, reject) => {
  const mobileValidate = phone && validatePhoneNumber(phone.replace(/\s/g, ''));
  const emailValidate = email && validateEmailAddress(email);
  if (!full_name || !email || !phone || !phone.replace(/\s/g, '') || (isNew && !password)) {
    reject('alerts.fill_all_fields');
  } else if (isNew && password != pass2) {
    reject('alerts.password_mismatch');
  } else if (emailValidate === false) {
    reject('alerts.wrong_email_format');
  } else if (mobileValidate === false) {
    reject('alerts.wrong_phone_format');
  } else if (password.length < 6) {
    reject('auth_register.validate_cell_pass_min');
  } else {
    resolve();
  }
});

export const isFullURL = (str) => {
  if (str == null) {
    return false;
  }
  return str.includes('http');
};

export const isEmpty = (string) => {
  if (string != null && string.length > 0) {
    return false;
  }
  return true;
};

export const ucFirst = (str) => {
  if (str == null || str == '') {
    return '';
  }
  return str.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()).join(' ');
};

export const getImageFullURL = (photo) => {
  if (isFullURL(photo)) {
    return photo;
  }
  if (photo === 'x') {
    return `${Config.USER_PROFILE_IMG_BASE_URL}default?`;
  }
  if (photo === 'default') {
    return `${Config.USER_PROFILE_IMG_BASE_URL}default?`;
  }
  return Config.USER_PROFILE_IMG_BASE_URL + (isEmpty(photo) ? 'default?' : photo);
};

export const convertTimestamp2Date = (timestamp) => {
  if (timestamp == null) return new Date();
  return new Date(timestamp.seconds * 1000);
};

export const convertTimeString2Hours = (time) => {
  if (time) {
    const tmpArray = time.split(':');
    if (tmpArray.length == 1) {
      return parseInt(tmpArray[0]);
    } if (tmpArray.length > 1) {
      return parseInt(tmpArray[0]) + parseInt(tmpArray[1]) / 60;
    }
  }
  return 0;
};

export const minutes2Days = (minutes) => {
  if (minutes == null) {
    return [];
  }
  const d = parseInt(minutes / (60 * 24));
  const h = parseInt((minutes % (60 * 24)) / 60);
  const m = parseInt(minutes % 60);

  return [d, h, m];
};

export const getHourMin = (timestring) => {
  if (timestring == null) {
    return '';
  }
  const tmp = timestring.split(':');
  if (tmp.length > 1) {
    return `${tmp[0]}:${tmp[1]}`;
  }
  return '';
};

export const seconds2Time = (seconds) => {
  // const h = parseInt(seconds / (60 * 60));
  const m = parseInt((seconds % (60 * 60)) / 60);
  const s = parseInt(seconds % 60);

  return `${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`;
  // return ((h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s));
};

export const checkInSameWeek = (date = new Date()) => {
  const week_days = [];
  const today = new Date();
  const day_week = today.getDay();
  for (let i = -day_week; i < 0; i++) {
    week_days.push(moment(today).add(i, 'days').format('DD/MM/YYYY'));
  }
  for (let i = 0; i < 7 - day_week; i++) {
    week_days.push(moment(today).add(i, 'days').format('DD/MM/YYYY'));
  }
  return week_days.includes(moment(date).format('DD/MM/YYYY'));
};

export const extractErrorMessage = (error) => {
  if (error == null) {
    return 'generic_error';
  }
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object') {
    if (error.length) {
      error = error[0];
    } else {
      error = error[Object.keys(error)[0]];
      if (typeof error === 'object') {
        error = error[0];
      }
    }
  }
  if (error == null) {
    return 'generic_error';
  }
  if (error.error) {
    error = error.error;
  }
  if (error.body) {
    error = error.body.error;
  }
  if (error.data) {
    error = error.data;
  }
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error.message === 'string') {
    return error.message;
  }
  try {
    Object.keys(error.message).forEach((key) => {
      if (typeof error.message[key] === 'string') {
        return error.message[key];
      }
      error.message[key].forEach((message) => message);
    });
  } catch (e) {
    return 'generic_error';
  }
};

export function getDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/Mac|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export const openExternalUrl = (url) => {
  try {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    // eslint-disable-next-line no-empty
  } catch (error) {
  }
};

export const getStartofDate=(date, offset = '00:00:00')=>{
  return moment((moment(date, "YYYY-MM-DD  hh:mm:ss").format("YYYY-MM-DD") + offset), "YYYY-MM-DD  hh:mm:ss")
}