import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { Provider } from 'react-redux';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RTLLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import store from './store';
import { IndexLayout } from './layouts/index';
import routes from './routes';
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <IndexLayout>
            <Switch>
              {routes.map(route => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={route.path}
                  exact={route.exact}
                />
              ))}
            </Switch>
          </IndexLayout>
        </BrowserRouter>
      </Provider>
    </ChakraProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
