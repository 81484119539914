import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { isEmpty } from 'utils/common';
import { Fonts } from 'theme/theme';

export default function PageHeader(props) {
  const {
    pageTitle, pageDesc, parentRouteName,
  } = props;

  const pageTitleColor = useColorModeValue('navy.700', 'white');
  const pageDescColor = useColorModeValue('textColor.1', 'white');

  return (
    <Flex
      w="100%"
      flexDirection={'column'}
      alignItems={'flex-start'}
      mb={'15px'}
    >
      <Text
        fontFamily={Fonts.bold}
        fontSize="24px"
        lineHeight={'125%'}
        color={pageTitleColor}
      >
        {pageTitle}
      </Text>
      {
        !isEmpty(pageDesc) && <Text mt={{ sm: '2px', md: '5px' }} color={pageDescColor} fontSize={16} lineHeight={'130%'} fontFamily={Fonts.light}>{pageDesc}</Text>
      }
    </Flex>
  );
}
