import { useQuery } from 'react-query';
import { OrderStatisticsService } from 'services/statistics';
import { CardChart } from 'components/statistics/components/CardChart';
import { useSaveQueryParams } from 'hooks/use-save-query-params';
import Card from 'components/card/Card';
import { CirclesWithBar } from 'react-loader-spinner';
import { Fonts } from 'theme/theme';

export const Revenue = (props) => {
  const { queryParams, onSave } = useSaveQueryParams();

  const { status, data, error, isError, isLoading } = useQuery(
    ['getRevenueData', queryParams],
    () => OrderStatisticsService.getRevenueData(queryParams)
  );

  if (isLoading) {
    return <Card align="center" w="100%" h="20rem" justify="center">
      <CirclesWithBar
        height="80"
        width="80"
        color="#22adc4"
        ariaLabel="bars-loading"
        wrapperStyle={{ margin: 'auto' }}
        wrapperClass=""
        visible={true}
      />
    </Card>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const chartData = [{
    name: 'Total Orders',
    data: data.data.map((d) => d.dataSets?.totalOrders || 0)
  },
  {
    name: 'Total Tax',
    data: data.data.map((d) => d.dataSets?.totalTax || 0)
  },
  {
    name: 'Total Profit',
    data: data.data.map((d) => d.dataSets?.totalPrice || 0)
  }];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#38BDF8', '#DBEAFE', '#2563EB'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '60%',
        // borderRadiusApplication: 'end',
        // borderRadiusWhenStacked: 'last',
      }
    },
    stroke: {
      colors: ["transparent"],
      width: 9
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#64748B'],
          fontSize: '12px',
          fontFamily: Fonts.regular,
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      categories: data.data.map((d) => d.labels),
      labels: {
        style: {
          colors: ['#64748B'],
          fontSize: '12px',
          fontFamily: Fonts.regular,
          fontWeight: 400,
        },
      },
    },
    legend: {
      fontSize: '14px',
      fontFamily: Fonts.semibold,
      labels: {
        colors: '#64748B',
      },
      itemMargin: {
        horizontal: 14,
        vertical: 5
      },
    }
  };

  console.log('data a', data?.data)
  return <CardChart
    title="Revenue Statistics"
    chartData={chartData}
    chartOptions={options}
    width={'100%'}
    isLoading={isLoading}
    onSave={onSave}
  />;
};
