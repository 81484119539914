import { ACTION_TYPES } from '../../constants';

const initialState = {
  isLoggedIn: null,
  user: {},
  language: 'sq',
};

const app = (state = initialState, action) => {
  switch (action.type) {
  case ACTION_TYPES.APP_LOGGED_IN:
    return { ...state, isLoggedIn: action.payload };
  case ACTION_TYPES.APP_SET_LANGE: {
    return { ...state, language: action.payload || 'sq' };
  }
  case ACTION_TYPES.APP_SET_USER_DATA:
    return { ...state, user: action.payload };

  default:
    return state;
  }
};

export default app;
