import React, { useState } from "react";
import { Box, SimpleGrid, Flex, Text, RadioGroup, Radio, Stack, Image } from "@chakra-ui/react";

export default function CustomersAmountBox({ label, value, desc, style }) {
  return (
    <Box px={{ base: '16px', md: '24px' }} mt={{base: '20px', md: '0px'}} bgColor={'#FFF'} style={style}>
      <Text color={'textColor.1'} fontSize={{ base: '14px', md: '16px' }} fontWeight={'semibold'} lineHeight={'160%'}>{label}</Text>
      <Text mt={{ base: '16px', md: '24px' }} mb={{ base: '4px', md: '8px' }} color={'textColor.0'} fontSize={{ base: '24px', md: '32px' }} fontWeight={'bold'} lineHeight={'140%'}>{value}</Text>
      <Text color={'textColor.1'} fontSize={{ base: '14px', md: '16px' }} fontWeight={'semibold'} lineHeight={'160%'}>{desc}</Text>
    </Box>
  );
}
