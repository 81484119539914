import React, { useState } from "react";
import { Box, SimpleGrid, Flex, Text, RadioGroup, Radio, Stack, Image } from "@chakra-ui/react";

export default function WalletAmountBox({ icon, label, value, style }) {
  return (
    <Box
      p={{ base: '16px', md: '24px' }}
      bgColor={'#fff'}
      borderRadius={'12px'}
      style={style}
    >
      <Flex direction={'row'} justify='flex-start' align="center">
        {icon}
        <Box ml={{ base: '8px', md: '16px' }}>
          <Text color={'textColor.1'} fontSize={{ base: '12px', md: '13px' }} fontWeight={'normal'} lineHeight={'160%'}>{label}</Text>
          <Text mt={'4px'} color={'textColor.0'} fontSize={{ base: '18px', md: '24px' }} fontWeight={'bold'} lineHeight={'140%'}>{value} L</Text>
        </Box>
      </Flex>
    </Box>
  );
}
