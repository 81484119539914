import { useQuery } from 'react-query';
import { FoodStatisticsService } from 'services/statistics';
import { CardChart } from 'components/statistics/components/CardChart';
import { useSaveQueryParams } from 'hooks/use-save-query-params';
import Card from '../../../../card/Card';
import { CirclesWithBar } from 'react-loader-spinner';
import { Fonts } from 'theme/theme';

export const TotalAmountDiscounts = (props) => {
  const { queryParams, onSave } = useSaveQueryParams();

  const { status, data, error, isError, isLoading } = useQuery(
    ['getTotalAmountDiscounts', queryParams],
    () => FoodStatisticsService.getTotalAmountDiscounts(queryParams)
  );

  if (isLoading) {
    return <Card align="center" w="100%" h="20rem" justify="center">
      <CirclesWithBar
        height="80"
        width="80"
        color="#22adc4"
        ariaLabel="bars-loading"
        wrapperStyle={{ margin: 'auto' }}
        wrapperClass=""
        visible={true}
      />
    </Card>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const chartData = [{
    name: 'TotalAmountDiscounts',
    data: data.data.map((d) => d.count)
  }];

  const options = {
    chart: {
      id: 'TotalOrdersCoupons',
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#64748B'],
          fontSize: '12px',
          fontFamily: Fonts.regular,
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      categories: data.data.map((d) => d.date),
      labels: {
        style: {
          colors: ['#64748B'],
          fontSize: '12px',
          fontFamily: Fonts.regular,
          fontWeight: 400,
        },
      },
    },
  };

  return <CardChart
    title="Total Amount Discounts"
    chartData={chartData}
    chartOptions={options}
    chartType={'area'}
    isLoading={isLoading}
    onSave={onSave}
  />;
};
