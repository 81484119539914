import {
  Flex,
  FormLabel,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Text,
  Icon,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';
// Custom components
import React from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import Svg_eyeoff from 'assets/img/auth/eye-off.svg'
import { Fonts } from 'theme/theme';
import './index.css';

export default function AuthInput(props) {
  // Chakra Color Mode
  const textColor = useColorModeValue('textColor.0', 'white');
  const iconColor = useColorModeValue('textColor.1', 'white');
  const [show, setShow] = React.useState(false);

  return (
    <InputGroup
      borderRadius={'12px'}
      height='56px'
      width={'100%'}
      border={'1px solid #E2E8F0'}
      px={{ base: '6px', md: '10px' }}
      style={props.style}
      className='auth-input'
      justifyContent={'center'}
      alignItems={'center'}
    >
      {
        props.icon
      }
      <Input
        fontSize='16px'
        lineHeight={'160%'}
        fontFamily={Fonts.regular}
        color={textColor}
        px={'12px'}
        placeholder={props.placeholder}
        type={props.type == 'password' ? (show ? 'text' : 'password') : (props.type ?? 'text')}
        {...props.field}
      />
      {
        props.type == 'password' &&
        <>
          {
            show ?
              <Image src={Svg_eyeoff} width={'20px'} onClick={() => setShow(pre => !pre)} />
              :
              <Icon
                color={iconColor}
                _hover={{ cursor: 'pointer' }}
                as={MdOutlineRemoveRedEye}
                fontSize={'20px'}
                onClick={() => setShow(pre => !pre)}
              />
          }
        </>
      }
    </InputGroup>
  );
}
