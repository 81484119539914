import { ApiFactory } from '../index';

const PREFIX = '/superadmin/users';

const API_LIST = {
  SUPERFANS: `${PREFIX}/superfans`,
  REFERRERS: `${PREFIX}/top-referrers`,
};

export const UsersService = {
  getSuperfans(searQuery = '') {
    return ApiFactory.get(`${API_LIST.SUPERFANS}${searQuery}`);
  },
  getReferrers(searQuery = '') {
    return ApiFactory.get(`${API_LIST.REFERRERS}${searQuery}`);
  },
};
