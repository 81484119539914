import { ApiFactory } from '../index';

const PREFIX = '/superadmin/orders';

const API_LIST = {
  FETCH_ALL_CARD_PAYMENTS: `${PREFIX}/card-payment`,
};

export const OrdersService = {
  getCardPayments(searQuery = '') {
    return ApiFactory.get(`${API_LIST.FETCH_ALL_CARD_PAYMENTS}${searQuery}`);
  },
};
