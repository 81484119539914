export const columnsDataFeedbacks = [
  {
    Header: 'No',
    accessor: 'id',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Vendor',
    accessor: 'vendor_name',
  },
  {
    Header: 'Users Sent',
    accessor: 'count',
  },
  {
    Header: 'Users Opened',
    accessor: 'read_count',
  },
  {
    Header: 'Schedule Date',
    accessor: 'schedule_time',
  },
  {
    Header: 'Sent Date',
    accessor: 'created_at',
  },
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  // },
];
