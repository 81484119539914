import {
  Box,
  Button, ButtonGroup,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import { useState, useEffect } from 'react';
import { MdBarChart } from 'react-icons/md';
import Chart from 'react-apexcharts';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { addDays, subDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { Fonts } from 'theme/theme';

export const CardChart = (
  {
    title,
    chartData,
    chartOptions,
    chartType = 'bar',
    width = 500,
    height = 300,
    CustomChart,
    isLoading,
    onSave,
    ...rest
  }
) => {
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' },
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' },
  );
  const { onClose, onOpen, isOpen } = useDisclosure();

  // const ChartComponent = CustomChart || BarChart;
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleSave = (dateState) => {
    const { startDate, endDate } = dateState[0];
    return {
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0],
    };
  };

  const handleClear = () => {
    setState([
      {
        startDate: subDays(new Date(), 7),
        endDate: new Date(),
        key: 'selection'
      }
    ]);
  };

  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="16px"
          fontFamily={Fonts.bold}
          lineHeight="140%"
        >
          {title}
        </Text>
        <Popover
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          focus-visible="false"
        >
          <PopoverTrigger>
            <Button
              align="center"
              justifyContent="center"
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              w="37px"
              h="37px"
              lineHeight="100%"
              borderRadius="10px"
              {...rest}
            >
              <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
            </Button>
          </PopoverTrigger>
          <PopoverContent w="100%" h="100%">
            <PopoverArrow />
            <PopoverHeader>{title}</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <DateRangePicker
                onChange={item => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
              />
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                <Button variant="outline" onClick={handleClear}>Clear</Button>
                <Button colorScheme="brand" onClick={() => onSave(handleSave(state))}>Done</Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </Flex>

      <Box h="21rem" mt="auto" overflow={'auto'}>
        {CustomChart ? <CustomChart /> : <Chart
          options={chartOptions}
          series={chartData}
          type={chartType}
          width={width}
          height={height}
        />}
      </Box>
    </Card>
  );
};
