import React, { useEffect, useState } from 'react';
import Card from 'components/card/Card.js';
import { Box, Grid, GridItem, useColorModeValue } from "@chakra-ui/react";
import UserInfo from './components/UserInfo';
import { useQuery } from 'react-query';
import { CustomerService } from 'services/customers';
import { useParams } from 'react-router-dom';
import { Fonts } from 'theme/theme';
import InterestsInfo from './components/InterestsInfo';

export default function CustomerDetails(props) {
    const [data, setData] = useState({});
    const { id: user_id } = useParams();
    const menus = ['General Information', 'Marketing', 'Orders', 'Friends', 'Interests'];
    const [curMenu, setCurMenu] = useState('Interests');
    
    const menuItemColor = useColorModeValue('gray.400', 'whiteAlpha.100');
    const activeMenuTxtColor = useColorModeValue('brand.300', 'white');
    const menuTxtColor = useColorModeValue('textColor.3', 'white');

    useEffect(() => {
        loadData(user_id);
    }, [user_id])

    const loadData = (id) => {
        CustomerService.get(`/${id}`)
            .then(({ data }) => {
                console.log(data?.customer)
                setData(data?.customer);
            })
            .catch((err) => {
            })
    }

    return (
        <>
            <UserInfo
                data={data}
            />
            <Grid
                mb='20px'
                templateColumns={{
                    base: "1fr",
                    md: "repeat(5, 1fr)",
                    lg: "repeat(5, 1fr)",
                }}
                gap={{ base: "20px", xl: "20px" }}>
                <GridItem colSpan={{ base: 1, md: 2, lg: 1 }}>
                    <Card mb={{ base: '8px', md: '12px' }} p={{ base: '8px', md: '12px' }} minH={'300px'} align="center">
                        {
                            menus.map(item =>
                                <Box
                                    borderRadius={'8px'}
                                    bgColor={curMenu == item ? menuItemColor : 'white'}
                                    color={curMenu == item ? activeMenuTxtColor : menuTxtColor}
                                    fontSize={14}
                                    lineHeight={'160%'}
                                    fontFamily={Fonts.medium}
                                    px={'12px'}
                                    py={'10px'}
                                    w={'100%'}
                                    textAlign={'left'}
                                    mb={'6px'}
                                    cursor={'pointer'}
                                    onClick={() => setCurMenu(item)}
                                >
                                    {item}
                                </Box>
                            )
                        }
                    </Card>
                </GridItem>
                <GridItem colSpan={{ base: 1, md: 3, lg: 4 }} minH={'300px'}>
                    <Card mb={{ base: '0px', lg: '20px' }} p={{ base: '16px', md: '24px', lg: '32px' }} minH={'300px'} align="center">
                        {
                            curMenu == 'Interests' &&
                            <InterestsInfo interests={data?.interests} />
                        }
                    </Card>
                </GridItem>
            </Grid>
        </>

    );
}
