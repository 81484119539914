import { Box, Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Fonts } from 'theme/theme';
import Svg_bg from 'assets/img/auth/signin.svg'

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;
  const bgColor = useColorModeValue('blue.2', 'blue.2')
  const descColor = useColorModeValue('gray.200', 'gray.200')
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh',
          xl: '100vh',
        }}
        w="100%"
        maxW={'100%'}
      >
        <Box
          display={{ base: 'none', md: 'block' }}
          h="100%"
          minH="100vh"
          w={'50%'}
          position='relative'
        >
          <Flex
            backgroundColor={bgColor}
            justify="center"
            direction={'column'}
            align="center"
            w="100%"
            h="100%"
            position="absolute"
          >
            <Image src={Svg_bg}
              maxWidth={'524px'}
              maxH={'524px'}
              width={'74%'}
              height={'57vh'}
              mb={{ base: '32px', md: '48px' }}
            />
            <Text fontSize={24} lineHeight='120%' color={'white'} fontFamily={Fonts.bold}>
              Superadmin Multipurpose Platform
            </Text>
            <Text mt={'12px'} fontSize={14} lineHeight='160%' color={descColor} fontFamily={Fonts.regular}>Manage Snapfood platform's preferences, user accounts, and core functionalities from one central hub.</Text>
          </Flex>
        </Box>
        {children}
      </Flex>
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
