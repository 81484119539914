import { boostFactory } from 'services/apiFactory';

const PREFIX_CAT = '/faqcats';
const PREFIX_FAQ = '/faq';

const API_LIST = {
  FETCH_CAT: `${PREFIX_CAT}`,
  FETCH_FAQ: `${PREFIX_FAQ}`
};

export const FaqCatService = {
  getAllCategory(searQuery = '') {
    return boostFactory.get(`${API_LIST.FETCH_CAT}${searQuery}`);
  },
  getCategoryList(){
    return boostFactory.get(`${API_LIST.FETCH_CAT}/getlist`);
  },
  createCategory(data) {
    return boostFactory.post(API_LIST.FETCH_CAT, data);
  },
  deleteCategory(data) {
    return boostFactory.post(`${API_LIST.FETCH_CAT}/delete`, data);
  },
  getAllFaq(searQuery = '') {
    return boostFactory.get(`${API_LIST.FETCH_FAQ}${searQuery}`);
  },
  createFaq(data) {
    return boostFactory.post(API_LIST.FETCH_FAQ, data);
  },
  deleteFaq(data) {
    return boostFactory.post(`${API_LIST.FETCH_FAQ}/delete`, data);
  },
};